<template>
  <div class="pop_wrap">
    <div class="icon" @click="openDialog">
      <img src="@/assets/images/dialog_icon.svg" alt="dialog" />
      <p class="tab">1</p>
    </div>
    <div class="dialog" v-show="showDialog">
      <div class="nav">
        <div class="title">TryHelp</div>
        <div class="close" @click="showDialog = false">
          <img src="@/assets/images/close_icon.svg" alt="close" />
        </div>
      </div>
      <div class="dialog_content">If you want to request a refund and cancel your subscription, or have other questions, click on <a href="https://tryhelp.net?utm_source=phonetictext" target="_blank">“tryhelp.net”</a></div>
    </div>
  </div>
</template>

<script>
  export default {
    name: '',
    data() {
      return {
        showDialog: false,
      };
    },
    methods: {
      openDialog() {
        this.showDialog = !this.showDialog;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @media (min-width: 1000px) {
    .pop_wrap {
      position: fixed;
      right: 20px;
      bottom: 60px;
      z-index: 9999;
      text-align: left;

      .icon {
        position: relative;
        cursor: pointer;
        .tab {
          position: absolute;
          top: -5px;
          right: -5px;
          background: red;
          color: #fff;
          width: 20px;
          height: 20px;
          line-height: 20px;
          border-radius: 50%;
          text-align: center;
        }
      }

      .dialog {
        position: absolute;
        bottom: 69px;
        right: 59px;
        width: 460px;
        height: 380px;
        background: #fff;
        box-sizing: border-box;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
        border-radius: 8px;

        .nav {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 50px;
          background-color: #329678;
          border-radius: 8px 8px 0px 0px;
          padding: 0 18px;

          .title {
            color: #fff;
            font-size: 22px;
          }

          .close {
            cursor: pointer;

            img {
              width: 18px;
            }
          }
        }

        .dialog_content {
          padding: 18px;
          font-size: 20px;
          line-height: 30px;

          a {
            color: #329678;
          }
        }
      }
    }
  }

  @media (max-width: 1000px) {
    .pop_wrap {
      position: fixed;
      right: 0.2rem;
      bottom: 1.5rem;
      z-index: 9999;
      text-align: left;

      .icon {
        width: 0.8rem;
        height: 0.6rem;
        cursor: pointer;

        img {
          width: 100%;
        }
        .tab {
          position: absolute;
          top: 0.15rem;
          right: 0;
          background: red;
          color: #fff;
          width: 0.3rem;
          border-radius: 50%;
          text-align: center;
          font-size: 0.24rem;
        }
      }

      .dialog {
        position: absolute;
        bottom: 0.65rem;
        right: 0.75rem;
        width: 6rem;
        height: 5rem;
        background: #fff;
        box-sizing: border-box;
        box-shadow: 0px 0.04rem 0.2rem rgba(0, 0, 0, 0.25);
        border-radius: 0.1rem;

        .nav {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 0.8rem;
          background-color: #329678;
          border-radius: 0.1rem 0.1rem 0px 0px;
          padding: 0 0.2rem;

          .title {
            font-size: 0.32rem;
            color: #fff;
          }

          .close {
            position: relative;
            width: 0.3rem;
            height: 0.3rem;
            cursor: pointer;

            img {
              position: absolute;
              top: 0;
              width: 100%;
            }
          }
        }

        .dialog_content {
          padding: 0.22rem;
          font-size: 0.28rem;
          text-align: inherit;

          a {
            color: #329678;
          }
        }
      }
    }
  }
</style>
