<template>
  <div>
    <div class="index">
      <div v-if="loading" class="is-loading">
        <div class="progress-box">
          <span class="font-regular">{{ ing }}%</span>
          <div ref="progress" class="progress"></div>
        </div>
        <h2 class="font-medium">{{ txt.loading.title }}</h2>
      </div>
      <div v-else class="pay-result">
        <h2 class="font-bold">{{ txt[status].title }}</h2>
        <p class="font-email" v-html="txt[status].des.replace('{email}', payEmail).replace('{superEmail}', superEmail)" />
        <button class="submit-btn" @click="handleSubmit">
          {{ txt[status].buttonTxt }}
        </button>
        <a href="/" class="font-bold tip" v-if="txt[status].tip">
          {{ txt[status].tip }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  import { checkOrder } from '@/api/pay';

  export default {
    name: 'Index',
    data() {
      return {
        ing: 10,
        timer: null,
        status: 'success',
        loading: true,
        outTime: 60,
        currentUserInfo: {
          email: '--',
          token: '',
        },
        superEmail: '',
        //  支付使用的邮箱
        payEmail: '',
        site: '',
      };
    },
    computed: {
      txt() {
        return {
          authorized: 'The login status is invalid, please log in to your account.',
          superEmail({ email, ofm_code }) {
            return `?subject=No Payment Results Found&body=User&nbsp;E-mail:&nbsp;${email || '--'}, \nOrder&nbsp;number:&nbsp;${ofm_code || '--'}, \nMy&nbsp;question&nbsp;is:&nbsp;`;
          },
          loading: {
            img: 'pay-resulting.webp',
            title: 'Payment processing...',
          },
          success: {
            img: 'modal-success-icon.webp',
            title: 'User created successfully',
            des: 'Congratulations! You can access all product features now. We have sent your account information and order information to your email: <span class="email font-bold">{email}</span>',
            buttonTxt: 'Enter Dashboard',
          },
          pending: {
            img: 'modal-success-icon.webp',
            title: 'Checking Payment Results',
            des: 'If you have already paid, please do not close the current page. The request for payment information may be delayed for a few minutes, please be patient. The payment results will be sent to <span class="highlight">{email}</span> by email. If you have any other questions, you can contact us through <a class="link-email" href="mailto:support@phonetictext.com{superEmail}">support@phonetictext.com</a>.',
            buttonTxt: 'Refresh',
            tip: 'Back to Homepage',
          },
          fail: {
            img: 'modal-fail-icon.webp',
            title: 'Something went wrong',
            des: "Something went wrong and we haven't received your payment. Please try again.",
            buttonTxt: 'Try Again',
          },
        };
      },
      userInfo() {
        return this.$store.state.userInfo;
      },
    },
    mounted() {
      this.polling(1000);
      this.context();
    },
    methods: {
      async context() {
        const response = await import('@/siteJson/common.json');
        const txt = await this.$replacePlaceholders(response.default);
        this.site = txt.NavBar.props.content.loginInfo;
      },
      polling(time) {
        this.timer = setInterval(() => {
          //  超时处理
          if (this.outTime === 0) {
            clearInterval(this.timer);
            this.status = 'pending';
            this.loading = false;
            return;
          }
          if (this.ing < 90) {
            let random = Math.floor(Math.random() * 5) || 3;
            let num = this.ing + random;
            this.triggerProgress(num);
          }
          this.outTime--;
          this.handleCheckOrder();
        }, time);
      },
      triggerProgress(num) {
        this.ing = num;
        if (!this.$refs.progress) return;
        this.$refs.progress.style.width = `${this.ing}%`;
      },
      handleCheckOrder() {
        const transparentExtend = JSON.parse(this.$route.query.transparentExtend || '{}');
        const data = {
          id: transparentExtend.id,
        };
        checkOrder(data)
          .then((res) => {
            console.log("🚀  res: >>>", res);
            //  假如不为0，且进度条到了100（避免重复触发）说明支付成功
            if (res.obj.status !== 0 && this.ing !== 100) {
              clearInterval(this.timer);
              this.triggerProgress(100);
              this.status = 'success';
              this.loading = false;
              this.payEmail = res.obj.email
            }
          })
          .catch((e) => {
            clearInterval(this.timer);
            this.status = 'pending';
            this.loading = false;
          });
      },
      handleSubmit() {
        if (this.status === 'pending') {
          this.outTime = 30;
          this.ing = 10;
          this.loading = true;
          this.polling(1000);
        } else {
          const has = localStorage.getItem('user_email');
          if (!has) {
            this.$store.commit('OPEN_LOGIN', true);
            sessionStorage.setItem('pushSta', 0);
          } else {
            this.$router.push({ path: '/sub/account' });
            this.$router.go(0);
          }
        }
      },
    },
    beforeDestroy() {
      clearInterval(this.timer);
    },
  };
</script>

<style scoped lang="scss">
  @media (min-width: 1000px) {
    .index {
      height: 600px;
      display: flex;
      justify-content: center;
      align-items: center;

      .is-loading {
        width: auto;
        height: auto;

        .picture {
          width: 160px;
          height: 160px;
          margin: 0 auto;
        }

        .progress-box {
          width: 800px;
          height: 40px;
          background: #87a89e;
          border-radius: 35px;
          position: relative;
          margin: 40px auto;

          span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 3;
            color: #ffffff;
          }

          .progress {
            position: absolute;
            width: 10%;
            height: 100%;
            top: 0;
            left: 0;
            transition: all 200ms;
            background: #329678;
            border-radius: 35px;
            z-index: 2;
          }
        }

        h2 {
          font-size: 28px;
          line-height: 32px;
          color: #000000;
          text-align: center;
        }
      }

      .pay-result {
        width: 800px;
        text-align: center;

        h2 {
          font-size: 46px;
          line-height: 50px;
          margin-top: 40px;
          margin-bottom: 24px;
        }

        p {
          font-size: 16px;
          line-height: 24px;

          &::v-deep {
            .email {
              font-size: 16px;
              color: #2a313d;
            }

            .highlight {
              font-weight: bold;
            }

            .link-email {
              color: #4e90f4;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }

        .submit-btn {
          display: block;
          margin: 40px auto 0 auto;
          width: 300px;
          height: 60px;
          border-radius: 10px;
          font-size: 24px;
          border: 1px solid #000000;
        }

        .tip {
          display: block;
          margin-top: 24px;
          font-size: 18px;
          color: #4e90f4;
          line-height: 21px;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  @media (max-width: 1000px) {
    .index {
      height: 10rem;
      display: flex;
      justify-content: center;
      align-items: center;

      .is-loading {
        width: auto;
        height: auto;

        .picture {
          width: 1.6rem;
          height: 1.6rem;
          margin: 0 auto;
        }

        .progress-box {
          width: 6.86rem;
          height: 0.4rem;
          background: #87a89e;
          border-radius: 0.35rem;
          position: relative;
          margin: 0.4rem auto;

          span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 3;
            color: #ffffff;
            font-size: 0.32rem;
          }

          .progress {
            position: absolute;
            width: 10%;
            height: 100%;
            top: 0;
            left: 0;
            transition: all 200ms;
            background: #329678;
            border-radius: 0.35rem;
            z-index: 2;
          }
        }

        h2 {
          font-size: 0.32rem;
          line-height: 0.32rem;
          color: #000000;
          text-align: center;
        }
      }

      .pay-result {
        width: 6.86rem;
        text-align: center;

        h2 {
          font-size: 0.48rem;
          line-height: 0.57rem;
          margin-top: 0.4rem;
          margin-bottom: 0.4rem;
        }

        p {
          font-size: 0.32rem;
          line-height: 0.48rem;

          &::v-deep {
            .email {
              font-size: 0.4rem;
              color: #2a313d;
            }

            .highlight {
              font-weight: bold;
            }

            .link-email {
              color: #4e90f4;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }

        .submit-btn {
          display: block;
          margin: 40px auto 0 auto;
          width: 300px;
          height: 60px;
          border-radius: 10px;
          font-size: 20px;
          border: 1px solid #000000;
        }

        .tip {
          display: block;
          margin-top: 0.32rem;
          font-size: 0.36rem;
          color: #4e90f4;
          line-height: 0.42rem;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
</style>
