<template>
  <!-- H5登录弹窗组件 -->
  <div class="mobile-header-container">
    <!-- <image src="" mode=""></image> -->
    <div class="mobile-header" :class="[scrollFill]" :style="{ background: scrollFill ? '#FFFFFF' : topEleBg }">
      <!-- transform: showRightPop ? 'translateY(-100%)' : 'translateY(0%)' -->
      <div class="row-between header-content">
        <img class="logo-icon" src="../assets/images/b-logo.webp" @click="toLink('index')" />
        <img v-show="!showRightPop" class="icon-right-open" src="../assets/images/icon-top_open.webp" @click="openSlide" />
        <img v-show="showRightPop" class="icon-right-open" src="../assets/images/icon-top_close.webp" @click="closeSlide" />
      </div>
    </div>
    <!-- <div class="mobile-span" v-show="!showRightPop" /> -->
    <div class="mobile-slide-container" :style="{ width: showRightPop ? '100%' : '0%', opacity: showRightPop ? 1 : 0 }" @click.stop="">
      <!-- <div class="mask" @click="showRightPop = !showRightPop"></div> -->
      <div class="slider" :style="{ transform: showRightPop ? 'translateX(0%)' : 'translateX(100%)' }">
        <img class="slider-close" src="../assets/images/mobile-close.webp" alt="" @click="showRightPop = !showRightPop" />
        <div class="slider-content">
          <div class="slider-list">
            <div v-for="(items, index) in optList" :key="index" class="slider-list-item" :class="{ expend: items.ish5Open }">
              <div class="child-box row-between" @click="handleItemClick(items, index)">
                <div class="row">
                  <!-- <img class="slider-item-icon" :src="$getFile(items.icon)" alt=""> -->
                  <div class="slider-item-title" :class="{ active: items.ish5Open }">{{ items.h5title }}</div>
                </div>
                <img v-show="items.list && items.list.length" class="icon-trangle" src="../assets/images/icon-trangle-down.webp" />
              </div>
              <div class="item-menu-list">
                <div v-for="(item, idx) in items.list" :key="'c' + idx" class="item-menu-item" @click="goPage(item.name)">
                  <div class="row">
                    {{ items.img }}
                    <img class="menu-img" :src="$getFile(item.img)" alt="" />
                    <div class="menu-title">
                      {{ item.title }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        scrollFill: '',
        showRightPop: false,
        optList: [],
        // 头像数组
        avatar: [require('../assets/images/avatar/avatar01.webp'), require('../assets/images/avatar/avatar02.webp'), require('../assets/images/avatar/avatar03.webp'), require('../assets/images/avatar/avatar04.webp'), require('../assets/images/avatar/avatar05.webp'), require('../assets/images/avatar/avatar06.webp')],
        headPortrait: '',
        menuItemHeight: 0,
        scrollPosition: 0,
      };
    },
    computed: {
      routerName() {
        return this.$route.name;
      },
      i18nText() {
        return this.$t('navBar.converts');
      },
      topEleBg() {
        return this.$store.state.topEleBg;
      },
    },
    watch: {
      showRightPop: {
        handler(newVal, oldVal) {
          if (newVal) {
            const $body = document.querySelector('body');
            const scrollPosition = window.pageYOffset;
            $body.style.overflow = 'hidden';
            $body.style.position = 'fixed';
            $body.style.top = `-${scrollPosition}px`;
            $body.style.width = '100%';
            this.scrollPosition = scrollPosition;
          } else {
            const $body = document.querySelector('body');
            $body.style.removeProperty('overflow');
            $body.style.removeProperty('position');
            $body.style.removeProperty('top');
            $body.style.removeProperty('width');
            window.scrollTo(0, this.scrollPosition);
          }
        },
      },
    },
    created() {},
    mounted() {
      for (const key in this.i18nText) {
        this.optList.push(this.i18nText[key]);
      }

      if (process.browser) {
        window.addEventListener('scroll', this.handleScroll);
      }
    },
    methods: {
      goPage(page) {
        this.$router.push({
          path: '/' + page,
        });
      },
      toLink(name) {
        this.showRightPop = false;
        if (name === 'login') {
          this.$store.commit('changeDialog', { name: 'FormLayout', active: 'Login' });
          return;
        }
        this.$router.push({
          name,
        });
      },
      openSlide() {
        this.showRightPop = true;
        // this.scrollFill = 'shadow'
      },
      closeSlide() {
        this.scrollFill = '';
        this.showRightPop = false;
      },
      toLogin() {},
      handleScroll() {
        if (window.scrollY === 0) {
          this.scrollFill = '';
        } else {
          this.scrollFill = 'shadow';
        }
      },
      handleItemClick(item, index) {
        this.optList.forEach((item, i) => index !== i && (this.optList[i].ish5Open = false));
        item.ish5Open = !item.ish5Open;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .shadow {
    box-shadow: 0rem 0.01rem 0.05rem 0rem rgba(184, 184, 184, 0.5);
  }
  @media (min-width: 1000px) {
    .mobile-header-container {
      display: none;
      .mobile-header {
        padding: 0.34rem 0.32rem;
        background-color: white;
        border-bottom: 1px solid #efefef;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 900;
        .header-content {
          .logo-icon {
            width: 2.86rem;
          }
          .icon-right-open {
            width: 0.4rem;
            height: 0.4rem;
          }
        }
      }
      .mobile-span {
        height: 1.2rem;
      }
      .mobile-slide-container {
        position: fixed;
        left: 0;
        right: 0;
        top: 1.2rem;
        z-index: 899;
        background-color: white;
        transition: all 0.2s ease-in-out;
        overflow: hidden;
        .slider {
          width: 100%;
          height: 100%;
          padding-bottom: 1rem;
          overflow-y: auto;
          position: relative;
          .slider-top {
            width: 100%;
            .slider-row-item {
              // cursor: pointer;
              // padding: .4rem .32rem;
              // border-bottom: 1px solid #eeeeee;
              // .slider-content {
              //   font-size: .32rem;
              //   text-align: left;
              // }
            }
          }
          .slider-bottom {
            width: 100%;
            padding: 0 0.48rem;
            .icon-golbal {
              width: 0.4rem;
              height: 0.4rem;
            }
            .language {
              color: #020b22;
              font-size: 0.28rem;
              line-height: 0.4rem;
            }
            .trigle {
              width: 0.16rem;
              height: 0.16rem;
            }
            .Login-btn {
              margin-top: 48rpx;
              margin-bottom: 48rpx;
              border-radius: 16rpx;
              background-color: #3879f1;
              font-size: 32rpx;
              color: white;
              height: 96rpx;
              width: 100%;
            }
            .active-btn {
              opacity: 0.7;
            }
          }
          .login-btn {
            background-color: var(--theme-primary);
            color: white;
            border-radius: 0.16rem;
            margin: 0.4rem 0.32rem 0;
            height: 1.12rem;
            &:hover {
              background-color: var(--theme-btn-hover);
            }
          }
        }
      }
    }
  }

  @media (max-width: 1000px) {
    .mobile-header {
      padding: 0.34rem 0.32rem;
      background-color: white;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 900;
      transition: all 0.2s ease;
      border-bottom: 1px solid #efefef;
      .header-content {
        .logo-icon {
          width: 2.86rem;
        }
        .icon-right-open {
          width: 0.4rem;
          height: 0.4rem;
        }
      }
    }
    .mobile-span {
      height: 1.2rem;
    }
    .mobile-slide-container {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      height: 100vh;
      overflow: hidden;
      transition: opacity 0.2s ease-in-out;
      opacity: 0;
      .mask {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 100%;
        background: rgba(0, 0, 0, 0.3);
        // -webkit-backdrop-filter: blur(15px);
        // backdrop-filter: blur(15px);
        // transition: all 0.3s ease-in-out;
      }
      .slider {
        position: absolute;
        transform: translateX(100%);
        right: 0;
        height: 100%;
        width: 100%;
        padding: 1.5rem 0.3rem;
        box-sizing: border-box;
        background-color: white;
        transition: all 0.3s ease-in-out;
        .slider-close {
          position: absolute;
          right: 0.32rem;
          top: 0.32rem;
          width: 0.4rem;
          height: 0.4rem;
        }
        .slider-header {
          padding: 0.12rem 0.1rem 0.4rem 0.1rem;
          border-bottom: 1px solid #dbd3fe;
          .avatar {
            width: 0.6rem;
            height: 0.6rem;
            flex: none;
            margin-right: 0.14rem;
          }
          .slider-header-name {
            color: #020b22;
            font-size: 0.32rem;
            line-height: 0.44rem;
            font-family: Poppins-Regular, Poppins;
          }
          .slider-header-email {
            color: #aeaeae;
            font-size: 0.24rem;
            line-height: 0.32rem;
            font-family: Poppins-Regular, Poppins;
          }
          &:active {
            opacity: 0.7;
          }
        }
        .slider-content {
          max-height: 90vh;
          overflow-y: scroll;
          overflow-x: hidden;
          .slider-list {
            max-height: 80vh;
            overflow: hidden auto;
            .slider-list-item {
              padding: 0.3rem 0;
              overflow: hidden;
              &.expend {
                padding-top: 0.3rem;
                // background-color: #F7F5FF;
                .child-box {
                  padding-bottom: 0.22rem;
                }
                .icon-trangle {
                  transform: rotate(180deg);
                }
                .item-menu-list {
                  height: auto;
                  .item-menu-item {
                    padding: 0.22rem 0;

                    &:active {
                      background: #eef6fa;
                    }
                    .menu-img {
                      width: 0.56rem;
                      height: 0.56rem;
                      margin-right: 0.16rem;
                      width: 0.56rem;
                      height: 0.56rem;
                      margin-right: 0.16rem;
                      flex: none;
                    }
                    .menu-title {
                      font-size: 0.28rem;
                      font-family: Poppins-Regular, Poppins;
                      font-weight: 400;
                      color: #000d29;
                      line-height: 0.76rem;

                      &:active {
                        background: #eef6fa;
                      }
                    }
                  }
                }
              }

              .child-box {
                .slider-item-icon {
                  width: 0.4rem;
                  height: 0.4rem;
                  margin-right: 0.23rem;
                  flex: none;
                }
                .slider-item-title {
                  font-size: 0.32rem;
                  font-family: Poppins-Medium, Poppins;
                  font-weight: 500;
                  color: #000d29;
                  line-height: 0.32rem;
                }

                // .active {
                //   color: #696EFF;
                // }
                .icon-trangle {
                  width: 0.18rem;
                  height: 0.11rem;
                  transition: transform 0.2s ease;
                }
              }
              &:active {
                // background-color: #F7F5FF;
                .slider-item-title {
                }
              }
              .item-menu-list {
                height: 0;
                overflow: hidden;
                transition: height 0.2s ease;
                .item-menu-item {
                  padding: 0.22rem 0;
                  &:not(:last-of-type) {
                    padding: 0.22 0 0;
                  }
                  .menu-img {
                    width: 0.56rem;
                    height: 0.56rem;
                    margin-right: 0.16rem;
                    flex: none;
                  }
                  .menu-title {
                    font-size: 0.28rem;
                    font-family: Poppins-Regular, Poppins;
                    font-weight: 400;
                    color: #000d29;
                    line-height: 0.32rem;

                    &:active {
                      background: #eef6fa;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
</style>
