<template>
  <div class="dropdown">
    <div ref="drop-content" :class="['dropdown-content']">
      <p class="dropdown-name" @mouseover="dropChange(content, 1)" @mouseleave="dropChange(content, 2)">
        {{ content.title }}
        <img :class="content.isOpen || 'rotate'" :src="$getFile('ui/icon-trangle.png')" alt="" />
      </p>
      <div :class="['dropdown-container', content.isOpen && 'dropdown-open']" :style="{ height: content.isOpen ? currentHeight + 'px' : 0 }" @mouseover="mouseHandle(content, 1)" @mouseleave="mouseHandle(content, 2)">
        <div ref="dropdown-box" class="dropdown-box">
          <div v-for="(child, _idx) in content.list" :key="_idx" class="dropdown-child">
            <a class="dropdown-ctx" :href="child.path">
              <img class="feature-content-img" :src="$getFile(child.img)" alt="" />
              <span>{{ child.title }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'DropDown',
    props: {
      content: {
        type: Object,
        required: true,
        default: () => {},
      },
    },
    data() {
      return {
        currentIdx: 0,
        currentHeight: '',
        ishover: false,
        tabHover: false,
      };
    },
    mounted() {
      const cb = (e) => {
        const el = this.$refs['drop-content'];
        if (!el.contains(e.target)) {
          this.dropStatus(this.content, false);
        }
      };
      document.body.addEventListener('click', cb);
      this.$once('hook:beforeDestroy', () => {
        document.body.removeEventListener('click', cb);
      });
    },
    methods: {
      dropChange(content, val) {
        this.tabHover = val === 1;
        content.isOpen = this.tabHover || (!this.tabHover && this.ishover);

        //  当前需要显示的元素高度
        this.currentHeight = this.$refs['dropdown-box'].offsetHeight;
      },
      dropStatus(content, isOpen) {
        content.isOpen = isOpen;
      },
      mouseHandle(content, val) {
        this.ishover = val === 1;
        content.isOpen = (this.ishover && !this.tabHover) || (!this.ishover && this.tabHover);
      },
    },
  };
</script>

<style scoped lang="scss">
  @media (min-width: 1000px) {
    .dropdown {
      display: flex;
      .dropdown-content {
        width: auto;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 40px;

        .dropdown-name {
          cursor: pointer;
          user-select: none;
          font-size: 14px;
          font-family: Poppins-Regular, Poppins;
          font-weight: 400;
          color: #05021e;
          line-height: 24px;

          img {
            width: 10px;
            height: 6px;
            margin-left: 6px;
            transition: all 200ms;
            transform: rotate(180deg);
          }
          .rotate {
            transform: rotate(0deg);
          }

          &:hover {
            opacity: 0.7;
          }
        }

        .dropdown-container {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: calc(100% + 16px);
          height: 0;
          overflow: hidden;
          transition: all 200ms;
          box-shadow: 0px 1px 5px 0px rgba(184, 184, 184, 0.5);
          border-radius: 8px;
          background: #ffffff;

          .dropdown-box {
            // display: grid;
            // grid-template-columns: repeat(1, 1fr);
            // gap: 0 20px;
            background: #ffffff;
            // transition: all 200ms;
            padding: 14px 0;
            // box-sizing: border-box;

            .dropdown-ctx {
              min-width: 228px;
              display: flex;
              align-items: center;
              padding: 10px 16px;
              // border-radius: 8px;
              // border: 2px solid transparent;

              &:hover {
                background: #eef6fa;
                // border-color: #E7E3FB;
              }
              img {
                width: 30px;
                height: 30px;
                margin-right: 10px;
              }

              span {
                font-size: 14px;
                font-family: Poppins-Regular, Poppins;
                font-weight: 400;
                color: #05021e;
                line-height: 20px;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1000px) {
    .dropdown {
      display: none;
    }
  }
</style>
