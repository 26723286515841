<template>
  <!-- 登录组件 -->
  <div>
    <div>
      <div class="login-btn" @click="showLogin = true" v-if="!userState">{{ loginInfo.loginTips }}</div>
      <el-dropdown @command="logoutBtn" v-else>
        <div class="login-btn">
          <span>{{ btnType }}</span>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="account" icon="el-icon-user">account</el-dropdown-item>
          <el-dropdown-item command="exit" icon="el-icon-switch-button">exit</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <div class="long-container" v-if="showLogin">
      <div class="login" @click="stopPro">
        <img class="close-icon" :src="$getFile(loginInfo.closeIcon)" alt="" @click="closeWrap(false)" />
        <div class="title">{{ loginInfo.title }}</div>
        <div class="des">{{ des }}</div>
        <div class="re-input">
          <div class="input">
            <input ref="inputs" v-model="emailValue" type="text" :placeholder="loginInfo.emaliPlaceholder" @focus="setFocus('num')" />
            <img v-show="isEmailInvalid" class="icon" src="" alt="" />
          </div>
          <div class="error-tip" v-html="emailError"></div>
        </div>
        <div class="re-input">
          <div class="input">
            <input v-model="passwordValue" type="password" :placeholder="loginInfo.passwordPlaceholder" @focus="setFocus('pass')" @keyup.enter="handleLogin()" />
            <img v-show="isPasswordInvalid" class="icon" src="" alt="" />
          </div>
          <div class="error-tip" v-html="passwordError"></div>
        </div>
        <button class="commit" @click="!loadStart && handleLogin()">
          <Loading v-if="loadStart" />
          <div v-else>{{ loginInfo.loginButton }}</div>
        </button>

        <!-- 忘记密码 -->
        <!-- <div class="tips-wrap">
          {{loginInfo.tips1}}
          <br>
          <span @click="switchingMethod(tipsType)" class="link">{{tipsType}}</span>
          <span @click="goPath('/reset')">{{loginInfo.tips3}}</span>
        </div> -->

        <div class="toast" v-if="toastState">
          <svg v-if="isSuccess" width="20" height="20" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8" cy="8.5" r="8" fill="#73DE9E" />
            <path d="M4 8.5L6.66667 11.5L12 5.5" stroke="white" stroke-width="2" stroke-linecap="square" />
          </svg>
          <svg v-else xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 18" fill="none">
            <circle cx="8" cy="8.96875" r="8" fill="#F14242" />
            <path d="M5 11.9688L11 5.96875" stroke="white" stroke-width="2" stroke-linecap="square" />
            <path d="M5 5.96875L11 11.9688" stroke="white" stroke-width="2" stroke-linecap="square" />
          </svg>
          <span :class="isSuccess ? 'success' : 'isErr'">{{ toastMess }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Loading from './ui/Loading.vue';
  import { mapState, mapMutations } from 'vuex';

  export default {
    name: 'LoginWrap',
    props: {},
    components: { Loading },
    data() {
      return {
        loadStart: false,
        loginType: 'login',
        emailValue: '',
        passwordValue: '',
        isEmailInvalid: false,
        isPasswordInvalid: false,
        des: '',
        tipsType: '',
        emailError: '',
        passwordError: '',
        toastState: false,
        toastMess: '',
        isSuccess: false,
        showLogin: false,
        userState: false,
      };
    },
    watch: {
      toastState(newtoastMess) {
        if (newtoastMess !== '') {
          setTimeout(() => {
            this.toastState = false;
            if (this.isSuccess) this.closeWrap(false);
          }, 3600);
        }
      },
      loadStart() {
        setTimeout(() => {
          this.loadStart = false;
        }, 3000);
      },
      trigger(value) {
        if (value) {
          this.showLogin = value;
          this.OPEN_LOGIN(false);
        }
      },
    },
    computed: {
      ...mapState(['userInfo', 'loginInfo']),
      trigger() {
        return this.$store.state.triggerOpen;
      },
    },
    mounted() {
      this.cheackLogin();
      this.$nextTick(() => {
        this.des = this.loginInfo.loginDes;
      });
    },
    methods: {
      ...mapMutations(['OPEN_LOGIN']),
      cheackLogin() {
        this.userState = localStorage.getItem('user_email');
        this.userState ? (this.btnType = this.userState) : (this.btnType = this.loginInfo.login);
      },
      logoutBtn(type) {
        if (type === 'account') {
          if (this.$route.path !== '/sub/account') this.$router.push({ path: '/sub/account' });
        } else {
          this.$api
            .Logout()
            .then((result) => {
              this.$store.commit('REMOVE_USER_INFO');
              this.cheackLogin();
              if (this.$route.path !== '/') this.$router.push({ path: '/' });
            })
            .catch((err) => {
              // console.log(err, '失败 >>>')
            });
        }
      },
      // 登录
      handleLogin() {
        this.loadStart = true;
        let reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
        if (!this.emailValue) {
          this.emailError = this.loginInfo.emailErrorNull;
          return;
        }
        if (!reg.test(this.emailValue)) {
          this.emailError = this.loginInfo.emailErrorNull;
          return;
        }
        if (!this.passwordValue) {
          this.passwordError = this.loginInfo.passwordErrorNull;
          return;
        }
        if (this.passwordValue.length < 6 || this.passwordValue.length > 30) {
          this.passwordError = this.loginInfo.passwordErrorText;
          return;
        }
        let apiName = this.des !== 'Create account' ? this.$api.Login : this.$api.Register;
        apiName({
          identityType: 'EMAIL',
          identifier: this.emailValue,
          credential: this.passwordValue,
          trackId: 'this',
        })
          .then((res) => {
            // console.log(res, '---->> 登录返回');
            if (res.success == 1) {
              let rest = res.obj || {};
              this.$store.commit('SET_USER_INFO', rest);
              this.cheackLogin();
              this.showLogin = false;
              this.OPEN_LOGIN(false);
              this.$router.push({ path: '/sub/account' });
              if (sessionStorage.getItem('pushSta') == 0) {
                sessionStorage.removeItem('pushSta');
                this.$router.go(0);
              }
              this.isSuccess = true;
            }
            this.toastMess = res.msg;
            this.toastState = true;
          })
          .catch((e) => {
            // console.log(e, '---->> 失败回调');
          });
      },
      // 切换
      switchingMethod(type) {
        if (type !== 'Log in') {
          this.des = this.loginInfo.createDes;
          this.tipsType = this.loginInfo.loginTips;
        } else {
          this.des = this.loginInfo.loginDes;
          this.tipsType = this.loginInfo.createtips;
        }
      },
      // 清空
      setFocus(type) {
        type == 'num' ? (this.emailError = '') : (this.passwordError = '');
      },
      // 阻止冒泡
      stopPro(event) {
        event.stopPropagation();
      },
      closeWrap(type) {
        this.showLogin = type;
        this.emailValue = '';
        this.passwordValue = '';
      },
    },
  };
</script>

<style scoped lang="scss">
  @media (min-width: 1000px) {
    .login-btn {
      color: #000029;
      font-size: 16px;
      display: flex;
      align-items: center;
      padding: 0 10px;

      span {
        display: inline-block;
        width: 100px;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .long-container {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 998;
      background: rgba(0, 0, 0, 0.3);

      .login {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 360px;
        height: 460px;
        border-radius: 8px;
        background: #fff;
        box-sizing: border-box;
        padding: 60px 40px 0;

        .close-icon {
          position: absolute;
          top: 20px;
          right: 20px;
          width: 15px;
          cursor: pointer;
        }

        .title {
          color: #202124;
          font-family: Poppins;
          font-size: 32px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          margin-bottom: 12px;
        }

        .des {
          color: #202124;
          font-family: Poppins;
          font-size: 22px;
          font-style: normal;
          font-weight: 600;
          margin-bottom: 24px;
        }

        .re-input {
          width: 100%;

          .input {
            height: 48px;
            box-sizing: content-box;
            position: relative;

            input {
              width: 100%;
              height: 97%;
              margin-bottom: 1px;
              padding-right: 32px;
              font-size: 16px;
              box-sizing: border-box;
              border: none;
              outline: none;
              border-bottom: 1px solid #dadce0;

              &:focus {
                border-bottom: 1px solid #000029;
              }

              &:hover {
                border-bottom: 1px solid #babec5;
              }
            }

            .icon {
              position: absolute;
              right: 0;
              width: 16px;
              padding: 6px;
              top: 50%;
              transform: translateY(-50%);
              cursor: pointer;

              &:hover {
                background: #f5f5f5;
              }
            }
          }

          .err-input {
            input {
              border-bottom: 1px solid #f14242;
            }
          }

          .error-tip {
            color: #f14242;
            font-family: Poppins;
            font-size: 14px;
            margin-top: 4px;
            transition: all 0.3s;
          }
        }

        .commit {
          position: relative;
          width: 100%;
          height: 48px;
          border-radius: 25px;
          background: var(--theme-primary);
          color: #fff;
          font-size: 18px;
          font-weight: 500;
          margin-top: 36px;
          cursor: pointer;
          border: none;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .tips-wrap {
          color: #202124;
          font-family: Poppins-Regular, Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin: 16px 0;

          span {
            color: #416beb;
            cursor: pointer;

            &:hover {
              color: #1e4acf;
            }

            &:active {
              color: #3258ce;
            }
          }
        }

        .toast {
          position: absolute;
          top: 36px;
          left: 40px;
          width: 280px;
          height: 52px;
          border-radius: 4px;
          box-shadow: 0px 2px 8px 0px rgba(53, 58, 74, 0.15);
          background: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          animation: showing 0.3s linear;

          .success {
            color: #4fcb81;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-left: 8px;
          }

          .isErr {
            color: #f14242;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-left: 8px;
          }
        }
      }
    }
  }

  @media (max-width: 1000px) {
    .login-btn {
      display: flex;
      align-items: center;
      color: #000029;
      line-height: 15px;
      padding: 0 0.2rem;

      span {
        display: inline-block;
        width: 60px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .long-container {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 998;
      background: rgba(0, 0, 0, 0.3);

      .login {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 6.4rem;
        height: 7.6rem;
        border-radius: 8px;
        background: #fff;
        box-sizing: border-box;
        padding: 0.3rem 0.4rem 0;

        .close-icon {
          position: absolute;
          top: 0.3rem;
          right: 0.3rem;
          width: 0.35rem;
          cursor: pointer;
        }

        .title {
          color: #202124;
          font-family: Poppins;
          font-size: 0.6rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          margin-top: 0.3rem;
          margin-bottom: 0.3rem;
        }

        .des {
          color: #202124;
          font-family: Poppins;
          font-size: 0.4rem;
          font-style: normal;
          font-weight: 600;
          margin-bottom: 0.1rem;
        }

        .re-input {
          .input {
            height: 1rem;
            position: relative;
            box-sizing: content-box;

            input {
              width: 100%;
              height: 0.5rem;
              padding-bottom: 0.1rem;
              padding-right: 0.2rem;
              box-sizing: border-box;
              border: none;
              outline: none;
              border-bottom: 1px solid #dadce0;
              font-size: 0.35rem;

              &:focus {
                border-bottom: 1px solid #3760f2;
              }

              &:hover {
                border-bottom: 1px solid #babec5;
              }
            }

            .icon {
              position: absolute;
              right: 0;
              width: 1rem;
              padding: 0.1rem;
              top: 50%;
              transform: translateY(-50%);
              cursor: pointer;

              &:hover {
                background: #f5f5f5;
              }
            }
          }

          .err-input {
            input {
              border-bottom: 1px solid #f14242;
            }
          }

          .error-tip {
            color: #f14242;
            font-family: Poppins;
            font-size: 0.26rem;
            margin-top: 0.25rem;
            transition: all 0.3s;
          }
        }

        .commit {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 1rem;
          line-height: 1rem;
          border-radius: 0.5rem;
          background: var(--theme-primary);
          color: #fff;
          font-size: 0.38rem;
          font-weight: 500;
          margin: 0 auto;
          margin-top: 0.8rem;
          cursor: pointer;
          border: none;
        }

        .tips-wrap {
          color: #202124;
          font-family: Poppins-Regular, Poppins;
          font-size: 0.26rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin: 0.2rem 0;

          span {
            color: #416beb;
            cursor: pointer;

            &:hover {
              color: #1e4acf;
            }

            &:active {
              color: #3258ce;
            }
          }
        }

        .toast {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 0.3rem;
          left: 0.6rem;
          width: 5rem;
          height: 0.8rem;
          border-radius: 0.2rem;
          box-shadow: 0px 0.02rem 0.4rem 0px rgba(53, 58, 74, 0.15);
          background: #fff;
          animation: showing 0.3s linear;
          font-size: 0.3rem;
          font-family: Poppins;
          font-style: normal;
          font-weight: 500;

          .success {
            color: #4fcb81;
            margin-left: 0.2rem;
          }

          .isErr {
            color: #f14242;
            margin-left: 0.2rem;
          }
        }
      }
    }
  }
</style>
