<template>
  <!-- 顶部导航栏组件 -->
  <div>
    <div class="nav-box">
      <div ref="navBar" :class="['nav-bar', 'shadow']" :style="{ background: '#fff' }">
        <div class="container row-between">
          <div class="nav-left">
            <a href="/" class="logo"> {{ content.title }}</a>
            <div v-for="(item, key) in content.converts" :key="key">
              <DropDown :content="item" />
            </div>
          </div>
          <div class="nav-right row">
            <a :href="content.fastLink" v-if="content.fastClick">{{ content.fastClick }}</a>
            <login-wrap />
          </div>
        </div>
        <top-dialog :show="showTools">
          <div class="tools-grid">
            <div v-for="(items, index) in converts1" :key="index">
              <div class="tool-title">{{ items.title }}</div>
              <a v-for="(item, idx) in items.list" :key="idx" :href="item.path" :to="{ path: item.path }">
                <div class="tool-name">{{ item.title }}</div>
              </a>
            </div>
          </div>
        </top-dialog>
      </div>
    </div>
    <div class="placeholder"></div>
  </div>
</template>

<script>
  import utils from '@/common/utils';
  import DropDown from './ui/DropDown.vue';
  import TopDialog from './ui/TopDialog.vue';

  export default {
    name: 'NavBar',
    components: { DropDown, TopDialog },
    props: {
      content: {
        type: Object,
        required: true,
        default: () => {},
      },
    },
    data() {
      return {
        color: '',
        // 手机端代码支持
        showMobileHeader: false,
        // end
        showTools: false,
        btnType: this.content.login,
      };
    },
    computed: {
      converts1() {
        return [];
        // const convertObj = Object.fromEntries(
        //   Object.keys(this.$t('navBar').converts).slice(3, this.$t('navBar').converts.length).map(key => [key, this.$t('navBar').converts[key]])
        // )
        // return convertObj
      },
    },
    beforeMount() {
      // 支持手机端代码
      this.headerCtrl();
      window.addEventListener('resize', this.headerCtrl.bind(this));
    },
    mounted() {
      // this.setFontColor(this.topEleBg)
      // end
      window.addEventListener('click', this.handleToolClick.bind(this));
      this.$store.commit('SAVE_LOGIN_INFO', this.content.loginInfo);
    },

    methods: {
      addPoint(name) {
        // this.$api.saveOperationLog({
        //
        //   event_name,
        //   event_key: event_name,
        // })
      },
      // handleLogin() {
      //   this.$store.commit('changeDialog', { name: 'FormLayout', active: 'Login' })
      // },
      toTools(items) {
        this.$router.push({ name: items.name });
      },
      headerCtrl(e) {
        if (document.body.getBoundingClientRect().width <= 980) {
          this.showMobileHeader = true;
        } else {
          this.showMobileHeader = false;
        }
      },
      // val：navBar背景色，通过计算得出navbar文字深浅颜色
      setFontColor(val) {
        this.$nextTick(() => {
          let bg = utils.set16ToRgb(val);
          let r = 0;
          if (val.match(/#\w{6,8}/)) {
            r = parseInt(bg.substr(4, 3));
          }
          this.color = 255 - r > 100 ? '#FFFFFF' : '#011E15';
        });
      },
      toolsBoxClick() {
        this.showTools = !this.showTools;
      },
      handleToolClick(e) {
        if (!e.path) {
          // 不存在则遍历target节点
          let target = e.target;
          e.path = [];
          while (target.parentNode !== null) {
            e.path.push(target);
            target = target.parentNode;
          }
          // 最后补上document和window
          e.path.push(document, window);
        }
        let converIndex = [...e.path].indexOf(this.$refs['convert-box']);
        if (!([...e.path].indexOf(this.$refs['tools-main']) === -1)) {
          this.showTools = !this.showTools;
        } else if (converIndex >= 0 && converIndex < 5) {
          this.showTools = true;
        } else {
          this.showTools = false;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  $commonColor: '#419EFF'; //统一颜色
  @mixin flex-around {
    display: flex;
    justify-content: space-around;
  }

  .shadow {
    box-shadow: 0 1px 5px 0 rgba(184, 184, 184, 0.5);
    border-bottom: none;
  }
  @media (min-width: 1000px) {
    .price-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 222px;
      height: 48px;
      background: #4083fc;
      border-radius: 30px;
      font-size: 20px;
      font-weight: bold;
      line-height: 24px;
      color: #ffffff !important;
      border: none;
    }
    .shadowNone {
      border-bottom: 1px solid #efefef;
    }

    .placeholder {
      height: 80px;
      width: 100%;
    }

    .nav-box {
      width: 100%;
      position: fixed;
      top: 0;
      z-index: 20;

      .nav-bar {
        font-size: 16px;
        font-family: Poppins-Regular, Poppins;
        font-weight: 400;
        color: #020b22;
        line-height: 20px;
        padding: 0px 100px;
        height: 80px;
        box-sizing: border-box;
        width: 100%;
        z-index: 2;
        box-sizing: border-box;
        transition: all 0.3s ease;
        position: relative;
        .container {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .nav-left {
            width: auto;
            @include flex-around;

            .logo {
              width: 179px;
              cursor: pointer;
              color: #000;
              font-size: 20px;
              font-weight: bold;
              text-transform: uppercase;
              white-space: nowrap;
              img {
                width: 100%;
              }
            }
            .logo {
              width: 179px;
              cursor: pointer;
              color: #000;
              font-size: 20px;
              font-weight: bold;
              text-transform: uppercase;
              white-space: nowrap;

              img {
                width: 100%;
              }
            }

            .item {
              padding: 10px 20px;
              user-select: none;

              a {
                cursor: pointer;
                display: block;
                text-decoration: none;
                color: #020b22;

                &:hover {
                  span {
                    opacity: 0.8;
                  }
                }
              }
            }

            .tools-main {
              position: relative;
              height: 100%;
              .tools-box {
                font-size: 16px;
                line-height: 25px;
                color: #05021e;
                padding: 0 60px;
                height: 100%;
                cursor: pointer;
                &:hover {
                  opacity: 0.7;
                }
                .icon-trangle {
                  width: 10px;
                  height: 6px;
                  margin-left: 7px;
                  transition: all 0.3s ease;
                }
              }
            }
          }
          .nav-right {
            position: relative;
            width: auto;
            height: 100%;
            @include flex-around;
            align-items: center;

            a {
              color: #000;
              margin-right: 10px;
              &:hover {
                text-decoration: underline;
              }
            }

            button {
              padding: 7px 39px;
              font-size: 16px;
              font-family: Poppins-Regular, Poppins;
              font-weight: 400;
              color: #ffffff;
              line-height: 26px;
              background: #000029;
              border-radius: 20px;
              cursor: pointer;
              &:hover {
                opacity: 0.8;
              }
            }
          }
        }

        .tools-grid {
          width: 90%;
          max-width: 1340px;
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          gap: 30px;
          margin: 0 auto;
          padding: 30px 0 41px;

          .tool-title {
            color: #777;
            font-size: 14px;
            line-height: 20px;
            font-family: Poppins-Medium, Poppins;
            text-align: left;
            margin-left: 12px;
            margin-bottom: 14px;
          }

          .tool-name {
            font-size: 14px;
            line-height: 46px;
            color: #05021e;
            padding-left: 12px;

            &:hover {
              background: #eef6fa;
            }
          }
        }
        .convert-box {
          position: absolute;
          background-color: white;
          width: 100%;
          left: 0;
          top: 80px;
          overflow: hidden;
          box-sizing: border-box;
          transition: height 0.3s ease;
          &.convert-shadow {
            box-shadow: 0 3px 5px 0 rgb(184 184 184 / 50%);
          }
          .convert-content {
            width: 820px;
            margin: 0 auto;
            padding: 32px 0 41px;
            .tools-title {
              color: #9c9ba6;
              font-size: 14px;
              line-height: 20px;
              font-family: Poppins-Medium, Poppins;
              text-align: left;
              margin-left: 12px;
            }
            .feature-list {
              margin-top: 12px;
              display: grid;
              grid-template-rows: repeat(4, 1fr);
              grid-auto-flow: column;
              column-gap: 40px;
              row-gap: 10px;
              .feature-list-item {
                padding: 5px 10px;
                border-radius: 8px;
                cursor: pointer;
                border: 2px solid #fff;
                &:hover {
                  border: 2px solid #e7e3fb;
                  background-color: #f4f2ff;
                }
                .feature-item-img {
                  width: 30px;
                  height: 30px;
                  margin-right: 10px;
                }
                .feature-text {
                  font-size: 16px;
                  line-height: 25px;
                  color: #05021e;
                  font-family: Poppins-Regular, Poppins;
                }
              }
            }
            .left {
            }
            .right {
            }
          }
        }
      }
    }
  }
  @media (max-width: 1000px) {
    .placeholder {
      height: 1.2rem;
      width: 100%;
    }
    .nav-box {
      width: 100%;
      position: fixed;
      top: 0;
      z-index: 20;

      .shadowNone {
        border-bottom: none;
      }

      .nav-bar {
        font-size: 16px;
        font-family: Poppins-Regular, Poppins;
        font-weight: 400;
        color: #020b22;
        line-height: 20px;
        padding: 0px 20px;
        height: 1rem;
        box-sizing: border-box;
        width: 100%;
        z-index: 2;
        box-sizing: border-box;
        transition: all 0.3s ease;
        position: relative;
        .container {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .nav-left {
            width: auto;
            @include flex-around;

            .logo {
              width: 179px;
              margin: 0 20px 0 0;
              cursor: pointer;
              color: #000;
              font-size: 24px;
              text-transform: uppercase;
              white-space: nowrap;
              font-weight: bold;
              img {
                width: 100%;
              }
            }

            .item {
              padding: 10px 20px;
              user-select: none;

              a {
                cursor: pointer;
                display: block;
                text-decoration: none;
                color: #020b22;

                &:hover {
                  span {
                    opacity: 0.8;
                  }
                }
              }
            }

            .tools-main {
              position: relative;
              height: 100%;
              .tools-box {
                font-size: 16px;
                line-height: 25px;
                color: #05021e;
                padding: 0 60px;
                height: 100%;
                cursor: pointer;
                &:hover {
                  opacity: 0.7;
                }
                .icon-trangle {
                  width: 10px;
                  height: 6px;
                  margin-left: 7px;
                  transition: all 0.3s ease;
                }
              }
            }
          }
          .nav-right {
            position: relative;
            width: auto;
            height: 100%;
            @include flex-around;
            align-items: center;

            a {
              color: #000;
              margin-right: 10px;
              &:hover {
                text-decoration: underline;
              }
            }

            user-center {
              display: none;
            }
            button {
              padding: 7px 39px;
              font-size: 16px;
              font-family: Poppins-Regular, Poppins;
              font-weight: 400;
              color: #ffffff;
              line-height: 26px;
              background: var(--theme-primary);
              border-radius: 20px;
              cursor: pointer;
              &:hover {
                background: var(--theme-btn-hover);
              }
            }
          }
        }

        .tools-grid {
        }
        .convert-box {
          position: absolute;
          background-color: white;
          width: 100%;
          left: 0;
          top: 80px;
          overflow: hidden;
          box-sizing: border-box;
          transition: height 0.3s ease;
          &.convert-shadow {
            box-shadow: 0 3px 5px 0 rgb(184 184 184 / 50%);
          }
          .convert-content {
            width: 820px;
            margin: 0 auto;
            padding: 32px 0 41px;
            .tools-title {
              color: #9c9ba6;
              font-size: 14px;
              line-height: 20px;
              font-family: Poppins-Medium, Poppins;
              text-align: left;
              margin-left: 12px;
            }
            .feature-list {
              margin-top: 12px;
              display: grid;
              grid-template-rows: repeat(4, 1fr);
              grid-auto-flow: column;
              column-gap: 40px;
              row-gap: 10px;
              .feature-list-item {
                padding: 5px 10px;
                border-radius: 8px;
                cursor: pointer;
                border: 2px solid #fff;
                &:hover {
                  border: 2px solid #e7e3fb;
                  background-color: #f4f2ff;
                }
                .feature-item-img {
                  width: 30px;
                  height: 30px;
                  margin-right: 10px;
                }
                .feature-text {
                  font-size: 16px;
                  line-height: 25px;
                  color: #05021e;
                  font-family: Poppins-Regular, Poppins;
                }
              }
            }
            .left {
            }
            .right {
            }
          }
        }
      }
    }
  }
</style>
