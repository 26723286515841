import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
Vue.config.productionTip = false
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import * as apis from '@/api';
Vue.prototype.$api = apis;

Vue.use(VueAwesomeSwiper)
Vue.prototype.$getFile = (name) => {
  return name && require('./assets/images/' + name)
}
//  处理url
Vue.prototype.$origin = function (path, isLink = false) {
  if (isLink) {
    return '/' + path
  }
  //  处理query，转化为链接格式
  const query = {}
  Object.keys(this.$route.query).forEach(key => {
    if (saveQueryKeys.includes(key)) {
      query[key] = this.$route.query[key]
    }
  })
  let target = this.$router.resolve({
    path, query
  })
  return window.location.origin + target.href
}
//替换占位符
Vue.prototype.$replacePlaceholders = async (obj) => {
  const siteInfo = await import("./siteJson/site.json");
  function replacePlaceholders(obj, placeholders) {
    // 遍历对象的所有属性
    for (let key in obj) {
      if (typeof obj[key] === 'object') {
        // 递归处理嵌套对象
        replacePlaceholders(obj[key], placeholders);
      } else if (typeof obj[key] === 'string') {
        // 替换占位符
        obj[key] = obj[key].replace(/\{\{(\w+)\}\}/g, (match, placeholder) => {
          return placeholders[placeholder] || match;
        });
      }
    }
  }
  console.log(obj, siteInfo)
  replacePlaceholders(obj, siteInfo)
  return obj
}
// 引入require.context函数
const requireComponent = require.context('./components', false, /\.vue$/);
requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName);
  const componentName = fileName.replace(/^\.\//, '').replace(/\.\w+$/, '');
  console.log(componentName, ">>>")
  Vue.component(componentName, componentConfig.default || componentConfig);
});

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
