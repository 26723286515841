<template>
  <div ref="convert-box" class="convert-box" :class="{ 'convert-shadow': show }" :style="{ height: show ? '244px' : '0px', 'border-top': show ? '1px solid #E5E5E5' : '' }">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'TopDialog',
    props: {
      show: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {};
    },
    computed: {},
    mounted() {},
  };
</script>

<style lang="scss" scoped>
  @media (min-width: 1000px) {
    .convert-box {
      position: absolute;
      background-color: white;
      width: 100%;
      left: 0;
      top: 80px;
      overflow: hidden;
      box-sizing: border-box;
      transition: height 0.3s ease;
      display: grid;
      grid-template-rows: 0fr;
      transition: grid-template-rows 0.5s;
      &.convert-shadow {
        box-shadow: 0 3px 5px 0 rgb(184 184 184 / 50%);
      }
    }
  }

  @media (max-width: 1000px) {
    .convert-box {
      position: absolute;
      background-color: white;
      width: 100%;
      left: 0;
      top: 80px;
      overflow: hidden;
      box-sizing: border-box;
      transition: height 0.3s ease;
      &.convert-shadow {
        box-shadow: 0 3px 5px 0 rgb(184 184 184 / 50%);
      }
    }
  }
</style>
