<template>
  <!-- 转换页转换过程组件 -->
  <div class="how2use">
    <h2 class="title">{{ content.title }}</h2>
    <p class="desc">{{ content.desc }}</p>
    <div class="how2use-list">
      <template v-for="(item, idx) in content.list">
        <div :key="idx" class="how2use-item">
          <img :src="$getFile(item.img, 'tools_images')" alt="" />
          <div>
            <p class="mini-title">{{ item.title }}</p>
            <span class="des">{{ item.des }}</span>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'How2use',
    props: {
      content: {
        type: Object,
        required: true,
        default: () => {},
      },
    },
    data() {
      return {};
    },
  };
</script>

<style scoped lang="scss">
  @media (min-width: 1000px) {
    .how2use {
      width: 1180px;
      padding: 100px 0;
      margin: 0 auto;
      text-align: center;
      .title {
        font-size: 36px;
        font-family: Poppins-Bold, Poppins;
        font-weight: bold;
        color: #060222;
        line-height: 56px;
      }

      .desc {
        font-size: 16px;
        font-family: Poppins-Regular, Poppins;
        font-weight: 400;
        color: #696eff;
        line-height: 24px;
        margin-top: 10px;
      }
      .how2use-list {
        margin-top: 48px;
        display: flex;
        justify-content: space-around;
        .how2use-item {
          width: 248px;
          img {
            width: 144px;
            height: 144px;
          }
          p {
            font-size: 20px;
            font-family: Poppins;
            color: #060222;
            line-height: 22px;
            margin-top: 24px;
            margin-bottom: 10px;
          }
          span {
            font-size: 16px;
            font-family: Poppins-Regular, Poppins;
            font-weight: 400;
            color: #060222;
            line-height: 22px;
          }
        }
      }
    }
  }

  @media (max-width: 1000px) {
    .how2use {
      width: 6.86rem;
      padding: 1.4rem 0;
      margin: 0 auto;
      text-align: center;
      .title {
        font-size: 0.48rem;
        font-family: Poppins;
        color: var(--theme-font-normal);
        line-height: 0.72rem;
        text-align: center;
      }

      .desc {
        max-width: 6.86rem;
        font-size: 0.28rem;
        font-family: Poppins-Regular, Poppins;
        font-weight: 400;
        color: #696eff;
        line-height: 0.48rem;
        margin-top: 0.16rem;
      }
      .how2use-list {
        margin-top: 0.56rem;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 0.8rem;
        text-align: left;
        .how2use-item {
          display: flex;
          align-items: center;
          img {
            width: 1.44rem;
            height: 1.44rem;
            margin-right: 0.24rem;
          }
          p {
            font-size: 0.32rem;
            font-family: Poppins;
            color: #060222;
            line-height: 0.4rem;
            margin-bottom: 0.1rem;
            margin-top: 0.21rem;
          }
          span {
            font-size: 0.28rem;
            font-family: Poppins-Regular, Poppins;
            font-weight: 400;
            color: #060222;
            line-height: 0.36rem;
          }
        }
      }
    }
  }
</style>
