<template>
  <div class="price-container" id="price">
    <h1 class="title">{{ content.title }}</h1>
    <p class="des">{{ content.des }}</p>
    <div class="price-list">
      <div class="price-setup" v-for="(item, idx) in content.priceSetup">
        <div style="display: flex; align-items: center; flex-wrap: wrap">
          <div v-html="item.price.replace(/{money}/g, productList[idx].money).replace(/{useTime}/g, productList[idx].useTime)"></div>
          <div class="tag">{{ item.tag }}</div>
        </div>
        <ul>
          <li class="des" v-for="line in item.desList">
            {{ line }}
          </li>
        </ul>
        <button :style="`cursor: ${loading ? 'wait' : ''}`" v-show="productList.length" class="pay-button" @click="handlePay(idx)">
          {{ item.buttonTxt }}
        </button>
        <p
          class="tip"
          v-html="
            item.tip
              .replace(/{money}/g, productList[idx].money)
              .replace(/{price}/g, productList[idx].price)
              .replace(/{useTime}/g, productList[idx].useTime)
          "
        ></p>
      </div>
    </div>
    <Popup />
  </div>
</template>

<script>
  const currencyMap = {
    USD: '$',
    PHP: '₱',
    ZAR: 'R',
    GBP: '£',
    SGD: 'S$',
    NZD: 'NZ$',
    EUR: '€',
    INR: '₹',
    CAD: 'C$',
    AUD: 'AUD',
    IDR: 'Rp',
    MYR: 'RM',
    KRW: '₩',
    HKD: 'HK$',
    TWD: 'NT$',
    BRL: 'R$',
    THB: '฿',
  };

  const offerTypePrices = {
    0: 'price',
    1: 'tryoutPrice',
    2: 'firstDiscountPrice',
  };

  const durationUnitType = {
    1: 'hour',
    2: 'day',
    3: 'week',
    4: 'month',
    5: 'year',
    100: 'Lifetime',
  };

  //  填充价格套餐
  const useFillPriceInfo = (item) => {
    item.money = item[offerTypePrices[item.offerType]];

    let unit = 'durationUnit';
    let duration = 'duration';
    if (item.offerType === 1) {
      unit = 'tryoutDurationUnit';
      duration = 'tryoutDuration';
    }
    const durationUnit = item[unit];
    const durationDate = item[duration];

    item.useTime = durationUnitType[durationUnit];
    if (durationDate === 100) {
      item.useTime = 'LifeTime';
    } else {
      item.useTime = durationDate + '-' + item.useTime;
    }

    item.currencyUnit = currencyMap[item.currency];

    return item;
  };

  import { getProductList, createOrder, payOrder } from '@/api/pay';
  import { website } from '@/siteJson/site.json';
  import Popup from '@/components/Pop-up.vue';
  export default {
    components: {
      Popup,
    },
    name: 'Banner',
    props: {
      payUrl: {
        type: Object,
        required: true,
        default: () => {},
      },
      content: {
        type: Object,
        required: true,
        default: () => {},
      },
    },
    data() {
      return {
        loading: false,
        //  审核马甲的订阅/一次性套餐可能顺序不同，按实际文案顺序调整
        productList: [
          {
            money: '39.99',
            price: '39.99',
            useTime: '1-month',
          },
          {
            money: '0.99',
            price: '39.99',
            useTime: '2-days',
          },
        ],
      };
    },
    created() {
      getProductList({ code: website, extend: {} }).then((res) => {
        if (res.result.length) {
          this.productList = res.result.map(useFillPriceInfo);
        }
      });
    },
    mounted() {
      if (this.$route.hash) {
        this.$nextTick(() => {
          document.querySelector(this.$route.hash) && document.querySelector(this.$route.hash).scrollIntoView();
        });
      }
    },
    methods: {
      async handlePay(idx) {
        if (!this.productList[idx].productsPriceList) {
          return this.nopay();
        }
        if (this.loading) return;
        this.loading = true;
        try {
          const order = await createOrder({
            productsId: this.productList[idx].id,
            productsPriceId: '0',
            successUrl: `${this.$origin(this.payUrl.successUrl || 'sub/pay-result')}`, //  支付成功url
            cancelUrl: `${this.$origin(this.payUrl.cancelUrl || 'sub/pay-result')}`, //  取消支付链接
            userExtend: {},
            transparentExtend: {},
            orderAdInfoExtend: {},
            marketingChannel: 'seo',
          });

          const result = await payOrder({
            orderId: order.obj.orderId,
            payExtend: {},
          });
          window.location.href = result.obj.redirectUrl;
        } catch (e) {
          this.loading = false;
          alert(e.msg || e);
        }
      },
      nopay() {
        alert('Please wait patiently for us to access the payment');
      },
    },
  };
</script>

<style scoped lang="scss">
  @media (min-width: 1000px) {
    .price-container {
      width: 1020px;
      padding: 50px 50px 0 50px;
      text-align: center;
      margin: 0 auto;
      .title {
        font-size: 36px;
        font-family: Roboto-Medium, Roboto;
        color: rgba(51, 51, 51, 1);
        line-height: 42px;
        text-align: center;
        margin-bottom: 24px;
      }
      .des {
        font-size: 16px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #333333;
        line-height: 24px;
        margin-bottom: 44px;
      }
      &::v-deep .high-bold {
        font-size: 24px;
        font-weight: bold;
        color: #333333;
        line-height: 28px;
      }
      &::v-deep .medium-bold {
        font-size: 14px;
        color: #7c7c7c;
        line-height: 18px;
        margin-top: 10px;
      }
      .price-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        text-align: left;
        .price-setup {
          min-width: 500px;
          padding: 32px;
          box-sizing: border-box;
          border: 4px solid #f2f8ff;
          margin: 0 auto;
          .tag {
            font-size: 14px;
            font-weight: 400;
            color: #76c225;
            line-height: 20px;
            padding: 2px 10px;
            background: #f2f8e9;
            border-radius: 12px;
            margin-left: 8px;
          }
          ul {
            margin-top: 16px;
            margin-bottom: 24px;
            li {
              margin-bottom: 8px;
            }
          }
          .pay-button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 222px;
            height: 48px;
            background: #4083fc;
            border-radius: 30px;
            font-size: 20px;
            font-weight: bold;
            line-height: 24px;
            color: #ffffff;
            border: none;
            &:hover {
              opacity: 0.8;
            }
          }
          .tip {
            font-size: 14px;
            color: #7c7c7c;
            line-height: 18px;
            margin-top: 10px;
          }
        }
      }
    }
  }
  @media (max-width: 1000px) {
    .price-container {
      width: 100%;
      margin: 1rem auto;
      text-align: center;
      padding: 0.32rem;
      box-sizing: border-box;
      .title {
        font-size: 36px;
        font-family: Roboto-Medium, Roboto;
        color: rgba(51, 51, 51, 1);
        line-height: 42px;
        text-align: center;
        margin-bottom: 24px;
      }
      .des {
        font-size: 16px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #333333;
        line-height: 24px;
        margin-bottom: 44px;
      }
      &::v-deep .high-bold {
        font-size: 24px;
        font-weight: bold;
        color: #333333;
        line-height: 28px;
      }
      &::v-deep .medium-bold {
        font-size: 14px;
        color: #7c7c7c;
        line-height: 18px;
        margin-top: 10px;
      }
      .price-list {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
        text-align: left;
        .price-setup {
          min-width: 100%;
          padding: 32px;
          box-sizing: border-box;
          border: 4px solid #f2f8ff;
          margin: 0 auto;
          .tag {
            font-size: 14px;
            font-weight: 400;
            color: #76c225;
            line-height: 20px;
            padding: 2px 10px;
            background: #f2f8e9;
            border-radius: 12px;
            margin-left: 8px;
          }
          ul {
            margin-top: 16px;
            margin-bottom: 24px;
            li {
              margin-bottom: 8px;
            }
          }
          .pay-button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 222px;
            height: 48px;
            background: #4083fc;
            border-radius: 30px;
            font-size: 20px;
            font-weight: bold;
            line-height: 24px;
            color: #ffffff;
            border: none;
            &:hover {
              opacity: 0.8;
            }
          }
          .tip {
            font-size: 14px;
            color: #7c7c7c;
            line-height: 18px;
            margin-top: 10px;
          }
        }
      }
    }
  }
</style>
