<template>
  <!-- 主页为什么选择组件 -->
  <div class="SpeechToText-warp">
    <el-upload class="upload-warp" ref="elupload" drag :limit="1" :on-exceed="handleExceed" :on-remove="handleRemove" :http-request="handleUpload" :auto-upload="true" :before-upload="beforeAvatarUpload" accept=".wav,.mp3,.wma,.m4a,.amr,.mid,.ape,.flac,.ogg" action>
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">
        Drag the file here, or <em>Click to upload</em>
        <div class="el-upload__tip">Only the audio files can be uploaded</div>
      </div>
    </el-upload>
    <div class="result-warp">
      <div class="result-box" v-loading="loading">
        {{ result }}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'SpeechToText',
    props: {
      expirationTime: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        attachmentUrl: [],
        loading: false,
        result: '',
      };
    },

    methods: {
      handleExceed(file, fileList) {
        this.$message({
          type: 'warning',
          message: 'Processing, please wait a moment...',
        });
      },
      handleRemove(file, fileList) {
        this.loading = false;
        source.cancel('Operation canceled by the user.');
      },
      async handleUpload(option) {
        console.log(option, 'option');
        // if (!this.expirationTime) {  // 非会员不能使用此功能
        //   this.$refs.elupload.clearFiles()
        //   this.$message({
        //     showClose: true,
        //     message: 'You are not a member yet and cannot use this feature temporarily!',
        //     type: 'error'
        //   });
        //   return
        // }
        this.result = '';
        this.loading = true;
        await this._getOSSSignature(option.file);
      },
      async _getOSSSignature(file) {
        const formData = new FormData(); // 创建一个FormData对象
        formData.append('file', file); // 添加文件到FormData对象
        this.$api
          .UploadFile(formData)
          .then((res) => {
            this.convert(res.obj);
          })
          .catch((e) => {
            this.loading = false;
          });
      },
      convert(fileKey) {
        this.$api.speechToText({ fileUrl: fileKey, languageType: 1 }).then((res) => {
          this.getResult(res.obj);
        });
      },
      getResult(uuid) {
        this.$api
          .GetResult({ uuid })
          .then((result) => {
            let time;
            if (result.success == '1') {
              this.result = result.obj;
              this.loading = false;
              clearTimeout(time);
            } else if (result.success == '50014') {
              time = setTimeout(() => {
                this.getResult(uuid);
              }, 3000);
            } else {
              this.loading = false;
              this.$message({
                type: 'error',
                message: 'conversion failures',
              });
            }
            this.$refs.elupload.clearFiles();
          })
          .catch((err) => {});
      },
      beforeAvatarUpload(file) {
        const isLt2M = file.size / 1024 / 1024 < 100;
        if (!isLt2M) {
          this.$message.warning('You can only upload files up to 100MB in total,please select again.');
        }
        return isLt2M;
      },
    },
  };
</script>

<style scoped lang="scss">
  @media (min-width: 1000px) {
    .SpeechToText-warp {
      background: #fff;
      border-radius: 16px;
      padding: 50px 50px;
      box-sizing: border-box;
      // display: flex;
      // align-items: center;
      .upload-warp {
        margin-bottom: 20px;
        &::v-deep {
          .el-upload {
            width: 100%;
          }
          .el-upload-dragger {
            width: 100%;
            border-radius: 16px;
          }
        }
      }
      .result-warp {
        .result-box {
          font-size: 14px;
          font-weight: 500;
          border: 1px solid #dedede;
          height: 158px;
          padding: 16px;
          border-radius: 16px;
        }
      }
    }
  }

  @media (max-width: 1000px) {
    .SpeechToText-warp {
      .upload-warp {
        margin: 0.5rem auto 0.2rem;
        &::v-deep {
          .el-upload {
            width: 100%;
          }
          .el-upload-dragger {
            width: 100%;
            border-radius: 16px;
          }
        }
      }
      .result-warp {
        .title {
          padding-left: 0.4rem;
          font-size: 0.36rem;
        }
      }
      .result-box {
        border: 1px solid #dedede;
        border-radius: 16px;
        padding: 20px;
        font-size: 14px;
        font-weight: 500;
        min-height: 10vh;
      }
    }
  }
</style>
