<template>
  <!-- 首页恢复类型组件 -->
  <div class="RecoveryType">
    <h2 class="title">{{ content.title }}</h2>
    <div class="des">{{ content.des }}</div>
    <div class="content">
      <div class="tab-title">
        <div v-for="(item, index) in content.tabList" :key="index" class="tabs" :class="{ active: tabIndex === index }" @click="cahngeTab(index)">
          <h2>{{ item.tabTitle }}</h2>
          <!-- <div v-if="index===0" class="exclusive">Exclusive</div> -->
        </div>
      </div>
      <div v-for="(item, index) in content.tabList" v-show="tabIndex === index" :key="index" class="content-item">
        <h2 class="item-title">{{ item.title }}</h2>
        <div class="item-des">{{ item.desc }}</div>
        <div class="item-list">
          <div class="list-left">
            <div v-for="(item1, idx) in item.childList" :key="idx" class="left-item" :class="{ itemActive: itemIndex === idx }" @click="changeIndex(idx)">
              {{ item1.name }}
            </div>
            <div class="line" :style="{ top: itemIndex * 60 + 'px' }"></div>
          </div>
          <div class="list-right">
            <img :src="$getFile(content.tabList[tabIndex].childList[itemIndex].image)" alt="" />
            <p>{{ content.tabList[tabIndex].childList[itemIndex].explain }}</p>
          </div>
        </div>
        <div class="item-list-H5">
          <div v-for="(item2, idx2) in item.childList" :key="idx2" class="items">
            <img :src="$getFile(item2.image)" alt="" />
            <p class="item-name">{{ item2.name }}</p>
            <p class="item-des">{{ item2.explain }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'RecoveryType',
    props: {
      content: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        tabIndex: 0,
        itemIndex: 0,
      };
    },
    computed: {
      // content() {
      //   return this.$t('popover.checkLogin')
      // }
    },
    methods: {
      cahngeTab(index) {
        this.tabIndex = index;
        this.itemIndex = 0;
      },
      changeIndex(index) {
        this.itemIndex = index;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @media (min-width: 1000px) {
    .RecoveryType {
      width: 100%;
      padding: 72px 0 100px;
      background: #f1faff;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .title {
        width: 1180px;
        font-size: 36px;
        font-family: Poppins;
        color: #060222;
        line-height: 52px;
        margin-bottom: 12px;
      }

      .des {
        width: 1180px;
        font-size: 16px;
        font-family: Poppins-Regular, Poppins;
        color: #000029;
        line-height: 24px;
        margin-bottom: 40px;
      }

      .content {
        .tab-title {
          display: flex;
          justify-content: space-between;
          flex-direction: row;
          width: 1180px;

          .tabs {
            width: 234px;
            height: 100px;
            background: #d4f0ff;
            border-radius: 8px 8px 0px 0px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            position: relative;

            h2 {
              width: 210px;
              font-size: 16px;
              font-family: Poppins-Medium, Poppins;
              font-weight: 500;
              color: #419eff;
            }

            .exclusive {
              position: absolute;
              left: 0;
              top: 0;
              width: 80px;
              height: 24px;
              background: linear-gradient(115deg, #ff7373 0%, #fd9a5e 100%);
              border-radius: 8px 0px 8px 0px;
              font-size: 12px;
              font-family: Poppins-Medium, Poppins;
              font-weight: 500;
              color: #ffffff;
              line-height: 24px;
            }
          }

          .active {
            background: linear-gradient(225deg, #32b4ff 0%, #696eff 100%);

            h2 {
              color: #fff;
            }
          }
        }

        .content-item {
          background-color: #fff;
          padding: 40px 49px;
          border-radius: 0px 0px 8px 8px;

          .item-title {
            font-size: 20px;
            font-family: Poppins;
            color: #000029;
            line-height: 32px;
            margin-bottom: 10px;
          }

          .item-des {
            max-width: 1080px;
            font-size: 16px;
            font-family: Poppins-Regular, Poppins;
            color: #000029;
            line-height: 24px;
          }

          .item-list {
            display: flex;
            justify-content: space-between;
            margin-top: 30px;

            .list-left {
              position: relative;
              border-right: 1px solid #eee;

              .left-item {
                width: 480px;
                height: 60px;
                line-height: 60px;
                font-size: 16px;
                font-family: Poppins-Medium, Poppins;
                font-weight: 500;
                color: #000000;
                cursor: pointer;
                position: relative;
                z-index: 3;
              }

              .itemActive {
                color: #419eff;
              }

              .line {
                width: 480px;
                right: -2px;
                top: 0;
                height: 60px;
                background: #f1faff;
                border-right: 3px solid #419eff;
                position: absolute;
                transition: top 200ms;
                z-index: 0;
              }
            }

            .list-right {
              margin-right: 35px;

              img {
                width: 500px;
                height: 360px;
              }

              p {
                width: 500px;
                font-size: 14px;
                font-family: Poppins-Regular, Poppins;
                color: #000029;
                line-height: 20px;
                margin-top: 20px;
              }
            }
          }

          .item-list-H5 {
            display: none;
          }
        }
      }

      .download-btn {
        margin-top: 42px;
      }
    }
  }

  @media (max-width: 1000px) {
    .RecoveryType {
      width: 100%;
      padding: 0.88rem 0 0.92rem;
      background: #f1faff;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .title {
        width: 6.86rem;
        font-size: 0.48rem;
        font-family: Poppins;
        color: #000029;
        line-height: 0.64rem;
        margin-bottom: 0.2rem;
      }

      .des {
        width: 6.86rem;
        font-size: 0.28rem;
        font-family: Poppins-Regular, Poppins;
        color: #000000;
        line-height: 0.36rem;
        margin-bottom: 0.48rem;
      }

      .content {
        width: 6.86rem;

        .tab-title {
          display: flex;
          justify-content: flex-start;
          overflow-x: scroll;

          .tabs {
            width: 4.68rem;
            height: 1.6rem;
            background: #d4f0ff;
            border-radius: 0.16rem 0.16rem 0rem 0rem;
            display: flex;
            margin-right: 0.02rem;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            position: relative;

            h2 {
              width: 4.2rem;
              font-size: 0.32rem;
              font-family: Poppins-Medium, Poppins;
              font-weight: 500;
              color: #ffffff;
              line-height: 0.42rem;
              color: #419eff;
              padding: 0 0.24rem;
            }

            .exclusive {
              position: absolute;
              left: 0;
              top: 0;
              width: 1.6rem;
              height: 0.48rem;
              background: linear-gradient(115deg, #ff7373 0%, #fd9a5e 100%);
              border-radius: 0.16rem 0px 0.16rem 0px;
              font-size: 0.24rem;
              font-family: Poppins-Medium, Poppins;
              color: #ffffff;
              line-height: 0.48rem;
            }
          }

          .active {
            background: linear-gradient(225deg, #32b4ff 0%, #696eff 100%);

            h2 {
              color: #fff;
            }
          }
        }

        .content-item {
          background-color: #fff;
          padding: 0.6rem 0.32rem 0.8rem;
          border-radius: 0rem 0rem 0.16rem 0.16rem;

          .item-title {
            width: 6.22rem;
            font-size: 0.42rem;
            font-family: Poppins;
            color: #060222;
            line-height: 0.48rem;
            margin-bottom: 0.16rem;
          }

          .item-des {
            width: 6.22rem;
            font-size: 0.28rem;
            font-family: Poppins-Regular, Poppins;
            color: #000000;
            line-height: 0.36rem;
          }

          .item-list {
            display: none;
          }

          .item-list-H5 {
            .items {
              margin-top: 0.64rem;
            }

            img {
              width: 6.22rem;
              border-radius: 0.16rem;
              margin-bottom: 0.24rem;
            }

            .item-name {
              width: 6.22rem;
              font-size: 0.32rem;
              font-family: Poppins;
              color: #000029;
              line-height: 0.4rem;
              text-align: center;
              margin-bottom: 0.12rem;
            }

            .item-des {
              width: 6.22rem;
              font-size: 0.28rem;
              font-family: Poppins-Regular, Poppins;
              color: #000000;
              line-height: 0.36rem;
            }
          }
        }
      }

      .download-btn {
        margin-top: 0.48rem;
      }
    }
  }
</style>
