import Vue from 'vue'
import Vuex from 'vuex'


import { GetUserInfo } from '@/api'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('user_token') || '',
    userInfo: {},
    loginInfo: false,
    triggerOpen: ''
  },
  getters: {
  },
  mutations: {
    SET_USER_INFO(state, userInfo) {
      state.userInfo = userInfo.userInfo;
      state.token = userInfo.tokenValue;
      localStorage.setItem('user_token_key', userInfo.tokenKey);
      localStorage.setItem('user_token_value', userInfo.tokenValue);
      localStorage.setItem('user_email', userInfo.userDetailVo && userInfo.userDetailVo.email || userInfo.userInfo && userInfo.userInfo.email);
    },
    REMOVE_USER_INFO(state) {
      state.userInfo = {};
      state.token = '';
      localStorage.removeItem('user_token_key');
      localStorage.removeItem('user_token_value');
      localStorage.removeItem('user_email');
    },
    SAVE_LOGIN_INFO(state, type) {
      state.loginInfo = type
    },
    OPEN_LOGIN(state, type) {
      state.triggerOpen = type
    }
  },
  actions: {
  },
  modules: {
  }
})
