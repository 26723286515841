<template>
  <!-- 主页为什么选择组件 -->
  <div class="whyChoose">
    <p class="title">{{ content.title }}</p>
    <p class="des">{{ content.des }}</p>
    <div class="why-area">
      <div v-for="(item, index) in content.resonList" :key="index" class="item">
        <p class="nums">{{ item.num }}</p>
        <p class="name">{{ item.des }}</p>
        <div class="line" />
        <p class="explain">{{ item.explain }}</p>
        <div v-if="item.isExclusive" class="exclusive">Exclusive</div>
      </div>
    </div>

    <div class="whyChoose-mobile">
      <div class="whyChoose-mobile-swiper-box">
        <swiper :options="swiperOption">
          <swiper-slide v-for="(item, idx) in content.resonList" :key="idx">
            <div class="whyChoose-item">
              <p class="nums">{{ item.num }}</p>
              <p class="name">{{ item.des }}</p>
              <div class="line" />
              <p class="explain">{{ item.explain }}</p>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'WhyChoose',
    props: {
      content: {
        type: Object,
        required: true,
        default: () => {},
      },
    },
    data() {
      return {
        swiperOption: {
          spaceBetween: -40,
          loop: true,
          loopAdditionalSlides: 1,
          slidesOffsetBefore: 25,
          slidesPerview: 2,
          slidesOffsetAfter: -30,
          autoplay: {
            delay: 3000,
            disableOnInteraction: false,
          },
        },
      };
    },
  };
</script>

<style scoped lang="scss">
  @media (min-width: 1000px) {
    .whyChoose {
      width: 100%;
      padding-top: 78px;
      padding-bottom: 100px;
      background: linear-gradient(135deg, #32b4ff 0%, #507e86 100%);
      margin-top: 121px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        max-width: 1180px;
        font-size: 36px;
        font-family: Poppins;
        color: #ffffff;
        text-align: center;
        line-height: 56px;
        margin-bottom: 12px;
      }

      .des {
        max-width: 1180px;
        font-size: 16px;
        font-family: Poppins-Regular, Poppins;
        color: #ffffff;
        text-align: center;
        line-height: 24px;
      }

      .why-area {
        width: 1180px;
        margin-top: 42px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;

        .item {
          padding: 30px;
          background: #ffffff;
          box-shadow: 0px 2px 8px 0px rgba(92, 104, 130, 0.1);
          border-radius: 8px;
          position: relative;
          overflow: hidden;
          transition: all 0.3s;
          transition: all 0.3s;

          &:hover {
            transform: translateY(-5px);
            -webkit-transform: translateY(-5px);
            -moz-transform: translateY(-5px);
            -ms-transform: translateY(-5px);
            -o-transform: translateY(-5px);
          }

          .nums {
            max-width: 320px;
            font-size: 40px;
            font-family: Poppins-Medium, Poppins;
            font-weight: 500;
            color: #419eff;
            line-height: 24px;
            margin-bottom: 10px;
          }

          .name {
            max-width: 320px;
            font-size: 16px;
            font-family: Poppins-Medium, Poppins;
            font-weight: 500;
            color: #060222;
            line-height: 22px;
            margin-bottom: 20px;
            text-align: center;
          }

          .line {
            width: 80px;
            height: 2px;
            background: linear-gradient(270deg, #8edef9 0%, #3162f6 100%);
          }

          .explain {
            margin-top: 20px;
            max-width: 320px;
            font-size: 16px;
            font-family: Poppins-Regular, Poppins;
            color: #060222;
            line-height: 22px;
          }

          .exclusive {
            position: absolute;
            right: -30px;
            top: 20px;
            width: 126px;
            height: 30px;
            background: linear-gradient(115deg, #ff7373 0%, #fd9a5e 100%);
            font-size: 16px;
            font-family: Poppins-Medium, Poppins;
            font-weight: 500;
            color: #ffffff;
            line-height: 30px;
            text-align: center;
            transform: rotate(45deg);
          }
        }
      }

      .whyChoose-mobile {
        display: none;
        overflow: hidden;
      }
    }
  }

  @media (max-width: 1000px) {
    .whyChoose {
      width: 100%;
      padding-top: 0.72rem;
      padding-bottom: 0.8rem;
      background: linear-gradient(135deg, #32b4ff 0%, #696eff 100%);
      margin-top: 1rem;

      .title {
        max-width: 6.86rem;
        font-size: 0.48rem;
        font-family: Poppins;
        color: #ffffff;
        line-height: 0.64rem;
        text-align: center;
        margin: 0 auto 0.2rem;
      }

      .des {
        width: 6.86rem;
        font-size: 0.28rem;
        font-family: Poppins-Regular, Poppins;
        color: #ffffff;
        line-height: 0.36rem;
        text-align: center;
        margin: 0 auto;
      }

      .why-area {
        display: none;
      }

      .whyChoose-mobile {
        margin-top: 0.6rem;
        .whyChoose-mobile-swiper-box {
          .whyChoose-item {
            padding: 0.51rem;
            width: 5.5rem;
            height: 3.9rem;
            background: #ffffff;
            box-shadow: 0rem 0.04rem 0.13rem 0rem rgba(92, 104, 130, 0.1);
            border-radius: 0.41rem;

            .nums {
              height: 0.82rem;
              font-size: 0.68rem;
              font-family: Poppins-Medium, Poppins;
              font-weight: 500;
              color: #419eff;
              line-height: 0.41rem;
              margin-bottom: 0.17rem;
            }

            .name {
              font-size: 0.28rem;
              font-family: Poppins-Medium, Poppins;
              font-weight: 500;
              color: #060222;
              line-height: 0.36rem;
              margin-bottom: 0.35rem;
            }

            .line {
              width: 1.36rem;
              height: 0.03rem;
              background: linear-gradient(270deg, #8edef9 0%, #3162f6 100%);
            }

            .explain {
              margin-top: 0.35rem;
              font-size: 0.28rem;
              font-family: Poppins-Regular, Poppins;
              color: #060222;
              line-height: 0.36rem;
            }
          }
        }
      }
    }
  }
</style>
