<template>
  <!-- 底部导航栏组件 -->
  <div class="footer-container">
    <div class="footer-box">
      <div class="top-container">
        <div class="main-container">
          <div class="footer-top">
            <div class="logo">{{ content.title }}</div>
            <div class="intro">{{ content.des }}</div>
          </div>
          <p class="introH5">{{ content.des }}</p>
          <div class="footer-list">
            <div v-for="(item, idx) in fastList" :key="idx" class="footer-item">
              <div class="item-title">{{ item.title }}</div>
              <p v-for="(target, tIdx) in item.list" :key="tIdx">
                <template v-if="target.type !== undefined">
                  <a :href="target.name">{{ target.title }}</a>
                </template>
                <template v-else>
                  <a :rel="target.rel || ''" :href="target.link" :class="target.active ? 'active' : ''" :target="target.active ? '_blank' : ''">{{ target.title }}</a>
                </template>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-container">
        <div class="bottom-box row-between">
          <div class="business-info">
            <div>{{ content.bottom.business }}</div>
            <img style="width:150px;" :src="$getFile('pay-bg2.png')" alt="" />
          </div>
        </div>
      </div>
      <!-- <div class="bottom-Link">
        <div v-for="(item, idx) in content.list[1].list" :key="idx">
          <a :rel="item.rel || ''" :href="item.link" @click="addPoint(item.link)">{{ item.title }}</a>
        </div>
      </div> -->
      <div class="bottom-mobile">
        <div>{{ content.bottomMobile }}</div>
        <img style="margin-top:0.2rem;" :src="$getFile('pay-bg2.png')" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'FooterBar',
    data() {
      return {
        direction: 'down',
      };
    },
    props: {
      content: {
        type: Object,
        required: true,
        default: () => {},
      },
    },
    computed: {
      fastList() {
        return this.content.list || [];
        // const list = []
        // for (let key in this.$t('navBar.converts')) {
        //   list.push(this.$t('navBar.converts')[key])
        // }
        // return list.concat(this.content.list).slice(0, 4)
      },
    },
    mounted() {},
    methods: {
      handleMenu(flag) {
        this.direction = flag ? 'up' : 'down';
      },
    },
  };
</script>

<style scoped lang="scss">
  .logo {
    cursor: pointer;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    white-space: nowrap;
  }

  @media (min-width: 1000px) {
    .footer-container {
      .footer-box {
        .top-container {
          width: auto;
          padding-top: 60px;
          background: #329678;
          color: #ffffff;

          .main-container {
            width: 90%;
            max-width: 1180px;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            margin: 0 auto;
            padding-bottom: 60px;
            border-bottom: rgba(255, 255, 255, 0.2) 2px solid;

            .footer-top {
              width: 300px;
              // background: url('@/static/images/w-logo.png') no-repeat;
              // background-size: 100%;

              img {
                width: 179px;
              }

              .intro {
                width: 280px;
                font-size: 14px;
                font-family: Poppins-Regular, Poppins;
                font-weight: 400;
                color: #ffffff;
                line-height: 22px;
                margin-top: 17px;
                margin-right: 60px;
              }
            }

            .footer-list {
              // display: grid;
              // grid-template-columns: repeat(4, 1fr);
              gap: 44px;
              display: flex;

              .footer-item {
                width: 160px;

                .item-title {
                  font-size: 16px;
                  font-family: Poppins-SemiBold, Poppins;
                  font-weight: 600;
                  color: #ffffff;
                  line-height: 24px;
                  margin-bottom: 20px;
                }

                p {
                  font-size: 16px;
                  font-family: Poppins-Regular, Poppins;
                  font-weight: 400;
                  line-height: 22px;
                  cursor: pointer;
                  text-wrap: nowrap;
                  word-wrap: break-word;

                  &:not(:last-child) {
                    margin-bottom: 16px;
                  }

                  a {
                    color: #ffffff;
                    text-decoration: none;

                    &:hover {
                      text-decoration: underline;
                    }
                  }

                  .active {
                    color: #f35454;
                  }
                }
              }
            }
          }

          .introH5 {
            display: none;
          }
        }

        .footer-line {
          height: 1px;
          background: #f8f8f8;

          .line-bar {
            height: 1px;
            background-color: rgba(255, 255, 255, 0.08);
            width: 1200px;
          }
        }

        .bottom-container {
          width: auto;
          padding: 20px 0;
          background: #329678;
          color: #ffffff;

          .bottom-box {
            width: 1180px;
            margin: 0 auto;

            .business-info {
              text-align: center;
              font-size: 12px;
              line-height: 19px;
              font-family: Poppins-Regular, Poppins;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;

              img {
                width: 300px;
                margin-left: 200rpx;
                position: absolute;
                right: 0;
              }
            }

            .bottom-Link {
              display: flex;
              justify-content: space-between;
              align-items: center;

              div {
                padding-right: 24px;
                padding-left: 24px;
                height: 12px;
                border-right: #cfcdd2 1px solid;

                &:last-child {
                  border: none;
                }

                a {
                  height: 12px;
                  font-size: 12px;
                  font-family: Poppins-Regular, Poppins;
                  font-weight: 400;
                  color: #cfcdd2;
                  line-height: 12px;
                  position: relative;
                  bottom: 3px;

                  &:hover {
                    text-decoration: underline;
                  }
                }
              }
            }

            .locale-box {
              cursor: pointer;

              .icon-locale {
                width: 20px;
                height: 20px;
                margin-right: 4px;
              }

              .locale-text {
                font-size: 14px;
                color: white;
                line-height: 20px;
                margin-right: 4px;
              }

              .triangle {
                transform: rotate(0deg);
                transition: transform 0.3s ease;
              }
            }
          }
        }

        .bottom-Link {
          display: none;
        }

        .bottom-mobile {
          display: none;
        }
      }
    }
  }

  @media (max-width: 1000px) {
    .logo {
      text-align: center;
    }

    .footer-container {
      .footer-box {
        width: auto;
        // padding: 1rem 0 0;
        background: #329678;
        color: #ffffff;

        .main-container {
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          margin: 0 auto;
          box-sizing: border-box;
          padding-top: 1rem;

          .footer-top {
            width: 3.57rem;
            height: 0.46rem;

            img {
              display: none;
            }

            .intro {
              display: none;
            }
          }

          .introH5 {
            height: 1.76rem;
            font-size: 0.28rem;
            font-family: Poppins-Regular, Poppins;
            font-weight: 400;
            color: #fff;
            line-height: 0.44rem;
            margin-top: 0.4rem;
            text-align: center;
          }

          .footer-list {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            flex-wrap: wrap;
            padding: 0.8rem 0;
            box-sizing: border-box;
            justify-content: space-between;
            gap: 0.78rem 0.8rem;

            .footer-item {
              width: 2.88rem;

              .item-title {
                font-size: 0.32rem;
                font-family: Poppins;
                color: #ffffff;
                line-height: 0.53rem;
                margin-bottom: 0.3rem;
              }

              p {
                font-size: 0.3rem;
                font-family: Poppins-Regular, Poppins;
                font-weight: 400;
                color: #fff;
                line-height: 0.46rem;
                cursor: pointer;
                text-wrap: nowrap;
                word-wrap: break-word;

                &:not(:last-child) {
                  margin-bottom: 0.32rem;
                }

                a {
                  color: #fff;
                  text-decoration: none;

                  &:hover {
                    text-decoration: underline;
                  }
                }

                .active {
                  color: #f35454;
                }
              }
            }
          }
        }

        .footer-line {
          height: 1px;
          background: #f8f8f8;

          .line-bar {
            height: 1px;
            background-color: #a09ca5;
            width: 6.86rem;
          }
        }

        .bottom-container {
          display: none;
        }

        .bottom-mobile {
          text-align: center;
          margin: 0 0.32rem;
          padding: 0.41rem 0 0.3rem;
          color: #fff;
          font-size: 0.24rem;
          line-height: 0.35rem;
          font-family: Poppins-Regular, Poppins;
          align-items: center;

          img {
            width: 3rem;
          }
        }

        .bottom-Link {
          width: 6.7rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0 auto;
          font-size: 0.24rem;
          font-family: Poppins-Regular, Poppins;
          font-weight: 400;
          color: #cfcdd2;
          line-height: 0.4rem;
          border-top: 0.02rem solid rgba(255, 255, 255, 0.2);

          div {
            margin-top: 0.4rem;

            a {
              color: #cfcdd2;
              text-decoration: none;
              border-right: #cfcdd2 1px solid;
              padding-right: 0.4rem;

              &:hover {
                text-decoration: underline;
              }
            }

            &:last-child {
              a {
                padding: 0;
                border: none;
              }
            }
          }
        }
      }
    }
  }
</style>
