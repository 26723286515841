import { render, staticRenderFns } from "./FooterBar.vue?vue&type=template&id=99e297d4&scoped=true"
import script from "./FooterBar.vue?vue&type=script&lang=js"
export * from "./FooterBar.vue?vue&type=script&lang=js"
import style0 from "./FooterBar.vue?vue&type=style&index=0&id=99e297d4&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "99e297d4",
  null
  
)

export default component.exports