<template>
  <!-- 子页恢复类型组件 -->
  <div class="recoverType">
    <h2 class="title">{{ content.title }}</h2>
    <p class="des">{{ content.des }}</p>
    <div class="tabs">
      <div v-for="(item, index) in content.tabList" :key="index" class="tabs-item" :class="{ active: index === acTag }" @click="acTag = index">
        <h2>{{ item.tag }}</h2>
      </div>
    </div>
    <div v-for="(item, index) in content.tabList" v-show="acTag === index" :key="index" class="child-item">
      <h2 class="ctitle">{{ item.title }}</h2>
      <div class="cdesc">{{ item.desc }}</div>
      <div v-if="acTag == 0" class="recover-list">
        <div v-for="(items, idx) in item.list" :key="idx" class="item">
          <img :src="$getFile(items.img)" alt="" />
          <p class="name">{{ items.name }}</p>
          <p class="explain" :style="{ 'text-align': isTextLeft ? 'left' : 'center' }" v-html="items.explain"></p>
        </div>
      </div>
      <div v-for="(items1, idx1) in item.list" v-show="acTag == 0" :key="idx1" class="items">
        <img :src="$getFile(items1.img)" alt="" />
        <div>
          <div class="name">{{ items1.name }}</div>
          <div class="explain">{{ items1.explain }}</div>
        </div>
      </div>
      <div v-if="acTag == 1" class="child-item1">
        <div class="child-left">
          <div v-for="(items2, idx2) in item.list" :key="idx2" class="left-item">
            <img :src="$getFile(items2.img)" alt="" />
            <div>
              <h2 class="name">{{ items2.name }}</h2>
              <div class="explain">{{ items2.explain }}</div>
            </div>
          </div>
        </div>
        <div><img class="child-right" :src="$getFile(item.img)" alt="" /></div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'RecoverType',
    props: {
      content: {
        type: Object,
        required: true,
        default: () => {},
      },
    },
    data() {
      return {
        acTag: 0,
        swiperOption: {
          spaceBetween: -40,
          loop: true,
          loopAdditionalSlides: 1,
          slidesOffsetBefore: 27,
          slidesPerview: 2,
          slidesOffsetAfter: -30,
          autoplay: {
            delay: 3000,
            disableOnInteraction: false,
          },
        },
      };
    },
    computed: {
      isTextLeft() {
        const map = ['/data-recovery-win'];
        return map.includes(this.$route.path);
      },
    },
  };
</script>

<style scoped lang="scss">
  @media (min-width: 1000px) {
    .recoverType {
      width: 100%;
      padding: 100px 0;
      background: #f1faff;
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        max-width: 1180px;
        font-size: 36px;
        font-family: Poppins;
        color: #060222;
        line-height: 56px;
        margin-bottom: 12px;
        text-align: center;
      }

      .des {
        max-width: 1180px;
        font-size: 16px;
        font-family: Poppins-Regular, Poppins;
        font-weight: 400;
        color: #05021e;
        line-height: 24px;
        margin-bottom: 40px;
        text-align: center;
      }

      .tabs {
        width: 1180px;
        border-radius: 8px;
        display: flex;

        .tabs-item {
          flex-grow: 1;
          width: 590px;
          text-align: center;
          height: 60px;
          background: #ffffff;
          border: 1px solid #518dff;
          border-left: none;
          line-height: 60px;
          font-size: 20px;
          font-family: Poppins-Medium, Poppins;
          font-weight: 500;
          color: #000029;
          cursor: pointer;

          &:hover {
            background: #eef6fa;
            // border-color: #E7E3FB;
          }

          &:first-child {
            border-radius: 8px 0 0 8px;
            border-left: 1px solid #518dff;
          }

          &:last-child {
            border-radius: 0 8px 8px 0;
          }
        }

        .active {
          background: linear-gradient(225deg, #32b4ff 0%, #696eff 100%);
          color: #ffffff;

          &:hover {
            background: linear-gradient(225deg, #32b4ff 0%, #696eff 100%);
            // opacity: .7;
          }
        }
      }

      .child-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 1180px;
        text-align: center;

        .ctitle {
          font-size: 20px;
          font-family: Poppins;
          color: #000029;
          line-height: 32px;
          margin-top: 30px;
        }

        .cdesc {
          font-size: 16px;
          font-family: Poppins-Regular, Poppins;
          color: #000029;
          line-height: 24px;
          margin-top: 10px;
        }

        .recover-list {
          margin-top: 30px;
          width: 100%;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 20px;

          .item {
            background: #ffffff;
            padding: 30px 20px;

            img {
              width: 120px;
            }

            .name {
              max-width: 340px;
              font-size: 18px;
              font-family: Poppins-Medium, Poppins;
              font-weight: 500;
              color: #060222;
              line-height: 24px;
              margin-top: 10px;
              margin-bottom: 4px;
            }

            .explain {
              font-size: 14px;
              font-family: Poppins-Regular, Poppins;
              font-weight: 400;
              color: #80848e;
              line-height: 22px;
            }
          }
        }

        .items {
          display: none;
        }

        .swiper-area {
          overflow: hidden;
          display: none;
        }
      }

      .child-item1 {
        max-width: 1176px;
        background: #ffffff;
        box-shadow: 0px 2px 8px 0px rgba(92, 104, 130, 0.1);
        border-radius: 16px;
        padding: 32px 32px 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        text-align: left;

        .child-left {
          display: flex;
          flex-direction: column;

          .left-item {
            display: flex;
            justify-content: flex-start;
            margin-top: 32px;

            &:first-child {
              margin-top: 0;
            }

            img {
              width: 60px;
              height: 60px;
              margin-right: 16px;
            }

            .name {
              max-width: 524px;
              font-size: 16px;
              font-family: Poppins;
              color: #000029;
              line-height: 24px;
              margin-bottom: 4px;
            }

            .explain {
              max-width: 524px;
              font-size: 14px;
              font-family: Poppins-Regular, Poppins;
              font-weight: 400;
              color: #000029;
              line-height: 22px;
            }
          }
        }

        .child-right {
          width: 486px;
          margin-left: 30px;
        }
      }

      .download-btn {
        margin-top: 42px;
      }
    }
  }

  @media (max-width: 1000px) {
    .recoverType {
      padding: 0.8rem 0.32rem 0.94rem;
      background: #f1faff;
      text-align: center;

      .title {
        max-width: 6.86rem;
        font-size: 0.48rem;
        font-family: Poppins;
        color: #05021e;
        line-height: 0.64rem;
        margin-bottom: 0.14rem;
      }

      .des {
        font-size: 0.28rem;
        font-family: Poppins-Regular, Poppins;
        font-weight: 400;
        color: #05021e;
        line-height: 0.43rem;
        margin-bottom: 0.42rem;
      }

      .tabs {
        border-radius: 0.16rem;
        display: flex;

        .tabs-item {
          flex-grow: 1;
          width: 1.15rem;
          text-align: center;
          padding: 0.14rem 0.35rem;
          background: #ffffff;
          border: 1px solid #518dff;
          border-left: none;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;

          &:first-child {
            border-radius: 0.16rem 0 0 0.16rem;
            border-left: 1px solid #518dff;
          }

          &:last-child {
            border-radius: 0 0.16rem 0.16rem 0;
          }

          h2 {
            line-height: 0.32rem;
            font-size: 0.28rem;
            font-family: Poppins-Medium, Poppins;
            font-weight: 500;
            color: #000029;
          }
        }

        .active {
          background: linear-gradient(225deg, #32b4ff 0%, #696eff 100%);

          h2 {
            color: #ffffff;
          }
        }
      }

      .child-item {
        display: flex;
        flex-direction: column;
        align-items: center;

        .ctitle {
          max-width: 6.4rem;
          font-size: 0.32rem;
          font-family: Poppins;
          color: #060222;
          line-height: 0.4rem;
          margin-top: 0.42rem;
        }

        .cdesc {
          max-width: 6.4rem;
          font-size: 0.28rem;
          font-family: Poppins-Regular, Poppins;
          font-weight: 400;
          color: #060222;
          line-height: 0.4rem;
          margin-top: 0.2rem;
          margin-bottom: 0.24rem;
        }

        .recover-list {
          display: none;
        }

        .items {
          display: flex;
          justify-content: flex-start;
          width: 6.86rem;
          background: #ffffff;
          border-radius: 0.16rem;
          border: 0.01rem solid #eeeeee;
          margin-top: 0.24rem;
          padding: 0.3rem;
          box-sizing: border-box;
          text-align: left;

          img {
            width: 1.18rem;
            height: 1.18rem;
            margin-right: 0.24rem;
            margin-top: -0.06rem;
          }

          .name {
            max-width: 4.84rem;
            font-size: 0.32rem;
            font-family: Poppins-Medium, Poppins;
            font-weight: 500;
            color: #060222;
            line-height: 0.4rem;
            margin-bottom: 0.1rem;
          }

          .explain {
            max-width: 4.84rem;
            font-size: 0.28rem;
            font-family: Poppins-Regular, Poppins;
            font-weight: 400;
            color: #83808e;
            line-height: 0.4rem;
          }
        }

        .swiper-area {
          margin-top: 0.48rem;
          width: 7.5rem;
          margin-left: -0.32rem;
          align-self: flex-start;

          // .swiper-slide {
          //   width: 6.46rem !important;
          // }

          .swiper-item {
            width: 6.46rem;
            padding: 0.4rem 0.4rem 0.88rem;
            box-sizing: border-box;
            height: 6rem;
            background: #ffffff;
            box-shadow: 0rem 0.03rem 0.14rem 0rem rgba(92, 104, 130, 0.1);
            border-radius: 0.32rem;

            img {
              width: 2.04rem;
              margin-bottom: 0.24rem;
            }

            .name {
              max-width: 5.66rem;
              font-size: 0.32rem;
              font-family: Poppins-Medium, Poppins;
              font-weight: 500;
              color: #060222;
              line-height: 0.4rem;
            }

            .explain {
              max-width: 5.66rem;
              font-size: 0.28rem;
              font-family: Poppins-Regular, Poppins;
              font-weight: 400;
              color: #060222;
              line-height: 0.36rem;
            }
          }
        }
      }

      .child-item1 {
        max-width: 6.86rem;
        background: #ffffff;
        box-shadow: 0rem 0.03rem 0.14rem 0rem rgba(92, 104, 130, 0.1);
        border-radius: 0.32rem;
        display: flex;
        align-items: center;
        flex-direction: column-reverse;
        margin-top: 0.18rem;
        text-align: left;
        padding: 0.32rem 0.32rem 0.48rem;

        .child-left {
          display: flex;
          flex-direction: column;

          .left-item {
            display: flex;
            justify-content: flex-start;
            margin-top: 0.5rem;

            img {
              width: 1rem;
              height: 1rem;
              margin-right: 0.24rem;
            }

            .name {
              max-width: 4.98rem;
              font-size: 0.32rem;
              font-family: Poppins;
              color: #000029;
              line-height: 0.48rem;
              margin-bottom: 0.12rem;
            }

            .explain {
              max-width: 4.98rem;
              font-size: 0.28rem;
              font-family: Poppins-Regular, Poppins;
              font-weight: 400;
              color: #000029;
              line-height: 0.42rem;
            }
          }
        }

        .child-right {
          width: 6.22rem;
        }
      }

      .download-btn {
        margin: 0.48rem auto 0;
      }
    }
  }
</style>
