<template>
  <div class="account">
    <div class="profile">
      <div class="title">{{ content.profile }}</div>
      <div class="user-info">
        <div class="user-info-left">
          <img class="user-icon" :src="$getFile('user-icon.png')" alt="" />
          <div>
            <div class="email-title">{{ content.enailTitle }}</div>
            <div class="email">{{ email }}</div>
          </div>
        </div>
        <div class="user-info-right">
          <div>{{ `Valid Until: ${formattedTime}` }}</div>
          <!-- <div>{{ expirationTime ? 'Subscription Plan' : 'Normal plan' }}</div> -->
          <div :class="[subscriptionStatus !== 1 ? 'submit—mini-los' : 'submit—mini']" @click="handleUnsubscribe">
            {{ content.unsubscribe }}
          </div>
        </div>
      </div>

      <el-form ref="form" label-position="top" :inline="true" :model="form" label-width="80px">
        <el-form-item :label="content.labalList.firstName">
          <el-input v-model="form.firstName" />
        </el-form-item>
        <el-form-item :label="content.labalList.lastName">
          <el-input v-model="form.lastName" />
        </el-form-item>
        <el-form-item :label="content.labalList.address">
          <el-input v-model="form.address" />
        </el-form-item>
        <el-form-item :label="content.labalList.country">
          <el-select v-model="form.country" :placeholder="content.inputTip" style="width: 360px">
            <el-option v-for="(item, index) in contryList" :key="index" :label="item.name" :value="item.iso" />
          </el-select>
        </el-form-item>
      </el-form>

      <div class="submit" @click="onSubmit">
        {{ loadStart ? '' : content.submitBtn }}
        <Loading v-show="loadStart" />
      </div>
    </div>
    <div class="last-wrap">
      <div class="title">Voice To Text results</div>
      <SpeechToText :expirationTime="expirationTime" />
    </div>
  </div>
</template>

<script>
  import { contryList } from '@/common/countries.js';
  import Loading from './ui/Loading.vue';
  export default {
    name: 'TermsOfUse',
    props: {
      content: {
        type: Object,
        required: true,
        default: () => {},
      },
    },
    components: { Loading },
    data() {
      let currenPass = (rule, value, callback) => {
        if (!value) {
          return callback(new Error(this.content.errTip1));
        }
        callback();
      };
      let newPass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error(this.content.errTip1));
        } else if (value.length < 6 || value.length > 32) {
          callback(new Error(this.content.errTip2));
        } else {
          callback();
        }
      };
      let checkPassport = (rule, value, callback) => {
        if (value === '') {
          callback(new Error(this.content.errTip3));
        } else if (value !== this.passForm.newPass) {
          callback(new Error(this.content.errTip4));
        } else {
          callback();
        }
      };
      return {
        email: '',
        expirationTime: false,
        loadStart: false,
        loading1: false,
        subscriptionStatus: false,
        formattedTime: '1999-10-10',
        form: {
          firstName: '',
          lastName: '',
          address: '',
          country: '',
        },
        passForm: {
          currenPass: '',
          newPass: '',
          checkPass: '',
        },
        contryList,
        rules: {
          currenPass: [{ required: true, validator: currenPass, trigger: 'blur' }],
          newPass: [{ required: true, validator: newPass, trigger: 'change' }],
          checkPass: [{ required: true, validator: checkPassport, trigger: 'blur' }],
        },
      };
    },
    mounted() {
      this.getInfo();
    },
    methods: {
      getInfo() {
        this.$api.GetUserInfo().then((res) => {
          if (res.success === '1') {
            this.form = res.obj;
            this.email = res.obj.email;
            this.subscriptionStatus = res.obj.subStatus;
            const now = new Date();
            const date = new Date(res.obj.expirationTime);
            const year = date.getFullYear(); // 获取年份
            const month = String(date.getMonth() + 1).padStart(2, '0'); // 获取月份，并补零
            const day = String(date.getDate()).padStart(2, '0'); // 获取日期，并补零
            this.formattedTime = `${year}-${month}-${day}`;
            if (date > now) {
              this.expirationTime = true;
            } else {
              this.expirationTime = false;
            }
          }
        });
      },
      onSubmit() {
        this.loadStart = true;
        this.$api
          .SetUserInfo(this.form)
          .then((res) => {
            if (res.success === '1') {
              this.$message({
                message: this.content.suceeTip1,
                type: 'success',
              });
              this.getInfo();
            }
          })
          .catch((err) => {
            if (err.msg) {
              this.$message({
                message: err.msg,
                type: 'error',
              });
            }
          })
          .finally(() => {
            this.loadStart = false;
          });
      },
      // 取消订阅
      handleUnsubscribe() {
        if (this.subscriptionStatus === 1) {
          this.$confirm('Do you confirm the unsubscription?', 'tips', {
            confirmButtonText: 'sure',
            cancelButtonText: 'cancel',
            type: 'warning',
          })
            .then(() => {
              this.$api
                .Unsubscribe({ reason: 'User manually clicks' })
                .then((res) => {
                  // if (res.success == 1) {
                  this.$message({
                    message: 'Unsubscribed successfully',
                    type: 'success',
                  });
                  this.getInfo();
                  // }
                })
                .catch((err) => {
                  this.$message({
                    message: 'Unsubscribed error',
                    type: 'error',
                  });
                  // console.log(err, '>>>> err')
                });
            })
            .catch(() => {
              console.log('cancel');
            });
        }
      },
      // resetPass() {
      //   this.loading1 = true
      //   this.$refs.passForm.validate((valid) => {
      //     if (valid) {
      //       this.$api.resetPassword({
      //         oldPassword: this.passForm.currenPass,
      //         newPassword: this.passForm.newPass
      //       }).then(res => {
      //         if (res.success === '1') {
      //           this.$message({
      //             message: this.content.suceeTip2,
      //             type: 'success'
      //           })

      //           this.$api.logout().then(res => {
      //             if (res.success === '1') {
      //               this.$cookies.remove('Authorization')
      //               this.$cookies.remove('userInfo')
      //               this.$store.commit('set_userInfo', {})

      //               this.$router.push({ path: '/' })
      //             }
      //           })
      //         }
      //       }).catch(err => {
      //         this.$message({
      //           message: err.msg,
      //           type: 'error'
      //         })
      //       }).finally(() => {
      //         this.loading1 = false
      //       })
      //     } else {
      //       console.log('error submit!!')
      //       this.loading1 = false
      //       return false
      //     }
      //   })
      // }
    },
  };
</script>

<style scoped lang="scss">
  @media (min-width: 1000px) {
    .account {
      width: 100%;
      background: #f2f3f5;
      display: flex;
      flex-direction: column;
      align-items: center;

      .profile {
        width: 1200px;
        height: 461px;
        border-radius: 8px;
        background: #fff;
        margin-top: 40px;
        padding: 24px 48px;
        box-sizing: border-box;
        margin-bottom: 100px;

        .title {
          color: #202124;
          font-family: Poppins-Medium;
          font-size: 26px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }

        .user-info {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 916px;
          border-bottom: 1px solid #f5f5f5;
          margin-bottom: 40px;

          .user-info-left {
            display: inline-flex;
            gap: 16px;
            align-items: center;
            margin-top: 24px;
            margin-bottom: 24px;

            .user-icon {
              width: 42px;
            }
          }
        }

        .user-info-right {
          display: flex;
          align-items: end;
          flex-direction: column;
          color: #202124;
          font-family: Poppins-Regular;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-transform: capitalize;

          div {
            margin-bottom: 24px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        .email-title {
          color: #202124;
          font-size: 14px;
          font-weight: 500;
          text-transform: capitalize;
          font-family: Poppins-Medium;
          line-height: normal;
        }

        .email {
          color: #adaeaf;
          font-size: 18px;
          font-weight: 400;
          margin-top: 5px;
        }

        ::v-deep .el-input {
          width: 360px;
        }

        ::v-deep .el-form-item__label {
          padding: 0;
        }

        ::v-deep .el-form-item {
          margin-right: 190px;
        }

        .submit {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 10px;
          font-family: Poppins-Medium;
          position: relative;
          width: 108px;
          height: 42px;
          border-radius: 4px;
          background: #329678;
          line-height: 42px;
          text-align: center;
          color: #fff;
          font-size: 14px;
          cursor: pointer;
        }

        .submit—mini {
          font-family: Poppins-Medium;
          height: 24px;
          padding: 0 10px;
          border-radius: 4px;
          background: #329678;
          line-height: 24px;
          text-align: center;
          color: #fff;
          font-size: 14px;
          cursor: pointer;
        }

        .submit—mini-los {
          font-family: Poppins-Medium;
          height: 24px;
          padding: 0 10px;
          border-radius: 4px;
          background: #565a59;
          line-height: 24px;
          text-align: center;
          color: #fff;
          font-size: 14px;
          cursor: no-drop;
        }
      }

      .last-wrap {
        width: 1200px;
        border-radius: 8px;
        background: #fff;
        margin-top: -40px;
        padding: 24px 48px;
        box-sizing: border-box;
        margin-bottom: 100px;

        .title {
          color: #202124;
          font-family: Poppins-Medium;
          font-size: 26px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }

      .password {
        width: 1200px;
        height: 444px;
        border-radius: 8px;
        background: #fff;
        margin-top: 40px;
        margin-bottom: 120px;
        padding: 24px 48px;
        box-sizing: border-box;

        .title {
          color: #202124;
          font-family: Poppins-Medium;
          font-size: 26px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }

        .explain {
          width: 927px;
          color: #adaeaf;
          font-family: Poppins-Regular;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-top: 4px;
          margin-bottom: 40px;
        }

        ::v-deep .el-input {
          width: 360px;
        }

        ::v-deep .el-form-item__label {
          padding: 0;
        }

        ::v-deep .el-form-item {
          margin-right: 190px;
        }

        .change-pass {
          margin-top: 10px;
          padding: 10px 28px;
          border-radius: 4px;
          background: #329678;
          color: #fff;
          font-size: 14px;
          line-height: normal;
          text-transform: capitalize;
          display: inline-block;
          cursor: pointer;
          position: relative;
        }

        .curren-pass {
          ::v-deep .el-input__inner {
            padding-right: 100px;
          }

          ::v-deep .el-input__clear {
            margin-right: 70px;
          }
        }

        .curren-pass-input {
          position: relative;
        }

        .forgot {
          color: #416beb;
          font-family: Poppins-Medium;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-transform: capitalize;
          position: absolute;
          right: 16px;
          top: 10px;
          cursor: pointer;

          &:hover {
            color: #329678;
          }
        }
      }

      ::v-deep .el-form-item__label {
        color: #797b80;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 4px;
      }

      ::v-deep .el-input__inner {
        color: #202124;
        font-family: Poppins-Regular;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  @media (max-width: 1000px) {
    .account {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .profile {
        border-radius: 0.08rem;
        background: #fff;
        margin-top: 0.34rem;
        padding: 0.3rem;
        box-sizing: border-box;
        margin-bottom: 1rem;

        .title {
          color: #202124;
          font-family: Poppins-Medium;
          font-size: 0.42rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }

        .user-info {
          // display: flex;
          // align-items: flex-start;
          // flex-direction: column;
          margin-bottom: 0.5rem;

          .user-info-left {
            display: inline-flex;
            gap: 0.35rem;
            align-items: center;
            margin-top: 0.4rem;
            margin-bottom: 0.4rem;

            .user-icon {
              width: 42px;
            }

            .email {
              color: #adaeaf;
              font-size: 0.28rem;
              font-style: normal;
              font-family: Poppins-Medium;
              font-weight: 400;
              line-height: normal;
              margin-top: 0.08rem;
            }

            .email-title {
              color: #202124;
              font-family: Poppins-Medium;
              font-size: 0.28rem;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              text-transform: capitalize;
            }
          }

          .user-info-right {
            color: #202124;
            font-family: Poppins-Regular;
            font-size: 0.28rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: capitalize;
            text-align: left;

            div {
              margin-bottom: 0.24rem;

              &:last-child {
                margin-bottom: 0;
              }
            }

            .submit—mini {
              margin-top: 10px;
              font-family: Poppins-Medium;
              position: relative;
              width: 96px;
              height: 24px;
              border-radius: 4px;
              background: #329678;
              line-height: 24px;
              text-align: center;
              color: #fff;
              font-size: 14px;
              cursor: pointer;
            }
          }
        }

        ::v-deep .el-input {
          width: 6.9rem;
        }

        ::v-deep .el-form-item__label {
          padding: 0;
        }

        ::v-deep .el-form-item {
          width: 6rem;
          margin-right: 0;
        }

        .submit {
          width: 100%;
          height: 0.84rem;
          font-family: Poppins-Medium;
          color: #fff;
          font-size: 0.28rem;
          font-style: normal;
          font-weight: 600;
          line-height: 0.84rem;
          text-transform: capitalize;
          position: relative;
          border-radius: 0.08rem;
          background: #329678;
          text-align: center;
          cursor: pointer;
        }
      }

      .last-wrap {
        width: 100%;
        border-radius: 0.08rem;
        background: #fff;
        padding: 0.3rem;
        box-sizing: border-box;
        margin-bottom: 1rem;

        .title {
          color: #202124;
          font-family: Poppins-Medium;
          font-size: 0.42rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          margin-bottom: 0.3rem;
        }
      }

      .password {
        width: 6.86rem;
        border-radius: 0.08rem;
        background: #fff;
        margin-top: 0.34rem;
        padding: 0.3rem;
        box-sizing: border-box;
        margin-bottom: 0.63rem;

        .title {
          color: #202124;
          font-family: Poppins-Medium;
          font-size: 0.42rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }

        .explain {
          color: #adaeaf;
          font-family: Poppins-Regular;
          font-size: 0.28rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-top: 0.1rem;
          margin-bottom: 0.5rem;
        }

        ::v-deep .el-input {
          width: 6.26rem;
        }

        ::v-deep .el-form-item__label {
          padding: 0;
        }

        ::v-deep .el-form-item {
          margin-right: 0;
        }

        .change-pass {
          width: 6.26rem;
          height: 0.84rem;
          margin-bottom: 0.2rem;
          font-family: Poppins-Medium;
          color: #fff;
          font-size: 0.28rem;
          font-style: normal;
          font-weight: 600;
          line-height: 0.84rem;
          text-transform: capitalize;
          border-radius: 0.08rem;
          background: #329678;
          text-align: center;
          position: relative;
        }

        .curren-pass {
          ::v-deep .el-input__inner {
            padding-right: 1.9rem;
          }

          ::v-deep .el-input__clear {
            margin-right: 1.3rem;
          }
        }

        .curren-pass-input {
          position: relative;
        }

        .forgot {
          color: #416beb;
          font-family: Poppins-Medium;
          font-size: 0.28rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-transform: capitalize;
          position: absolute;
          right: 0.2rem;
          top: 0.16rem;
          cursor: pointer;
        }
      }
    }

    ::v-deep .el-form-item__label {
      color: #797b80;
      font-size: 0.28rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 0.08rem;
    }

    ::v-deep .el-input__inner {
      color: #202124;
      font-family: Poppins-Regular;
      font-size: 0.32rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  ::v-deep .el-button--primary {
    background-color: #416beb;
    border-color: #416beb;

    &:hover {
      background-color: #329678;
      border-color: #329678;
    }
  }
</style>
