<template>
  <!-- 首页banner 组件 -->
  <div class="banner-container">
    <div class="banner-box">
      <div class="banner-left">
        <h1 class="title">{{ content.title }}</h1>
        <p class="des">{{ content.des }}</p>
        <p class="des1">{{ content.des1 }}</p>
      </div>
      <div class="banner-right">
        <img :src="$getFile(content.img)" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Banner',
    props: {
      content: {
        type: Object,
        required: true,
        default: () => {},
      },
      source: {
        type: String,
        default: '',
      },
    },
    data() {
      let that = this;
      return {
        activeIdx: 0,
        swiperOptions: {
          initialSlide: 0,
          loop: false,
          effect: 'fade',
          speed: 200,
          autoplay: {
            disableOnInteraction: false,
          },
          on: {
            transitionStart() {
              that.activeIdx = this.realIndex;
            },
          },
        },
      };
    },
    computed: {
      topEleBg() {
        return this.$store.state.topEleBg;
      },
    },
    methods: {
      swiperSwitch(idx) {
        this.$refs.swiper.swiper.slideTo(idx + 1);
      },

      SetPoint() {
        this.$api.saveOperationLog('Download_win_btncli', { source: this.source || 'home' });
      },
    },
  };
</script>

<style scoped lang="scss">
  @media (min-width: 1000px) {
    .banner-container {
      overflow: hidden;

      .banner-box {
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        align-items: center;
        padding-top: 22px;
        padding-bottom: 24px;
        padding-left: 20px;
        padding-right: 20px;
        background: var(--theme-home-primary);
        width: 90%;
        max-width: 1580px;
        border-radius: 16px;
        margin-top: 32px;
        margin: 32px auto 0;
        .banner-left {
          width: 627px;
          height: auto;
          .title {
            font-size: 46px;
            font-family: Poppins;
            color: var(--theme-font-normal);
            line-height: 52px;
            margin-bottom: 4px;
            margin-top: -10px;
          }
          .des {
            max-width: 626px;
            font-size: 18px;
            font-family: Poppins-Bold, Poppins;
            font-weight: bold;
            color: #696eff;
            line-height: 28px;
          }

          .des1 {
            font-size: 14px;
            font-family: Poppins-Regular, Poppins;
            font-weight: 400;
            color: var(--theme-font-normal);
            line-height: 24px;
            margin-bottom: 20px;
          }
          button {
            padding: 17px 78px;
            font-size: 18px;
            font-family: Poppins-Medium, Poppins;
            font-weight: 500;
            color: #ffffff;
            line-height: 29px;
            border-radius: 32px;
            cursor: pointer;
            background: var(--theme-primary);
            &:hover {
              background: var(--theme-btn-hover);
            }
          }
          .btn-desc {
            display: inline-block;
            width: 210px;
            height: 56px;
            background: linear-gradient(135deg, #32b4ff 0%, #696eff 100%);
            box-shadow: 0px 2px 6px 0px #9ecdef;
            border-radius: 28px;
            text-align: center;
            font-size: 18px;
            font-family: Poppins-Medium, Poppins;
            font-weight: 500;
            color: #ffffff;
            line-height: 56px;
            transition: top 0.3s;
            position: relative;
            top: 0;

            &:hover {
              background: linear-gradient(270deg, #5e67eb 0%, #2fa4eb 100%);
              top: -4px;
            }

            img {
              width: 24px;
              margin-right: 10px;
            }
          }
        }
        .banner-right {
          width: 464px;
          margin-right: 67px;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }
  @media (max-width: 1000px) {
    .banner-container {
      // background: #F4F2FF;
      // margin-bottom: .32rem;
      .banner-box {
        padding: 0.78rem 0 0.81rem;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;
        background: var(--theme-home-primary);
        margin: 0.32rem 0.32rem 0;
        border-radius: 0.24rem;

        .banner-left {
          width: 6.86rem;
          margin: 0 auto;
          text-align: center;
          .title {
            font-size: 0.56rem;
            padding: 0 0.2rem;
            font-family: Poppins;
            color: var(--theme-font-normal);
            line-height: 0.72rem;
            margin-bottom: 0.2rem;
            margin-top: 0.3rem;
          }
          .des {
            max-width: 6.46rem;
            font-size: 0.32rem;
            font-family: Poppins-Bold, Poppins;
            font-weight: bold;
            color: #696eff;
            line-height: 0.4rem;
            margin: 0 auto 0.2rem;
          }
          .des1 {
            font-size: 0.28rem;
            font-family: Poppins-Regular, Poppins;
            font-weight: 400;
            color: var(--theme-font-normal);
            line-height: 0.4rem;
            padding: 0 0.2rem;
          }
          button {
            padding: 0.17rem 0.78rem;
            font-size: 0.18rem;
            font-family: Poppins-Medium, Poppins;
            font-weight: 500;
            color: #ffffff;
            line-height: 0.29rem;
            display: none;
            border-radius: 0.32rem;
            cursor: pointer;
            background: var(--theme-primary);
            &:hover {
              background: var(--theme-btn-hover);
            }
          }
          .btn-desc {
            color: #ffffff;
            display: inline-block;
            width: 5.4rem;
            height: 1.12rem;
            background: linear-gradient(135deg, #32b4ff 0%, #696eff 100%);
            box-shadow: 0rem 0.04rem 0.12rem 0rem #9eb1ef;
            border-radius: 0.56rem;
            font-weight: 500;
            font-size: 0.36rem;
            font-family: Poppins-Medium, Poppins;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0.48rem auto 0;

            &:active {
              background: linear-gradient(270deg, #5e67eb 0%, #2fa4eb 100%);
            }

            img {
              width: 0.48rem;
              margin-right: 0.2rem;
            }
          }
        }
        .banner-right {
          width: 6.46rem;
          margin: 0 auto;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }
</style>
